import { Props } from "../../types";
import MainLayout from "../../components/layouts/MainLayout";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import useTitle from "../../hooks/useTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Select from 'react-select';
import { getPostalCode } from "../../store/postalcode/postalCodeSlice";
import { getZone, storeZone, updateZone } from "../../store/zone/zoneSlice";
import { countries, states } from "../../config/constants";
import { errorAlert } from "../../store/notifications/toasterSlice";
import LeafletMap from "../../components/map";
function Zone(props: Props) {
    useTitle("Create Delivery Zone");
    const initialValues = {
        name: "",
        street: "",
        city: "",
        state: {
            value: "AB",
            label: "Alberta"
        },
        zipCode: "",
        country: {
            value: "CA",
            label: "Canada"
        },
        zoneID: "",
        zipCodes: [],
        serviceFees: 0,
        deliveryCharges: 0,
        tip: 0,
        minimumOrderAmount: 0,
        minimumOrderAmountWithFee: 0,
        driverPerTripPayout: 0,
    }
    const [formInputs, setFormInputs] = useState(initialValues);
    const validationSchema = yup.object().shape({
        name: yup.string().required("Zone name is a required field."),
        country: yup.object({
            value: yup.string().required("Country is a required field."),
            label: yup.string().required("Country is a required field.")
        }),
        state: yup.object({
            value: yup.string().required("State is a required field."),
            label: yup.string().required("State is a required field.")
        }),
        city: yup.string().required("City is a required field."),
        street: yup.string(),
        zipCode: yup.string(),
        zoneID: yup.string().required("Zone ID is a required field."),
        zipCodes: yup.array().min(1, "Zip Code must have at least 1 items."),
        serviceFees: yup.number().required("Service Fees is a required field."),
        deliveryCharges: yup.number().required("Delivery charge is a required field."),
        tip: yup.number().required("Tip is a required field."),
        minimumOrderAmount: yup.number().required("Minimum order amount is a required field."),
        minimumOrderAmountWithFee: yup.number().required("Minimum order amount with fee is a required field."),
        driverPerTripPayout: yup.number()
    });
    const [uiData, setUiData] = useState<any>({
        countries: {
            loading: true,
            data: []
        },
        states: {
            loading: true,
            data: [],
        },
        postalCodes: {
            loading: false,
            fetching: false,
            isAdded: false,
        },
    })
    const { id } = useParams();
    const [editMode, setEditMode] = useState(false);
    const [pointsData, setPointsData] = useState<{ lat: number; lng: number }[]>([]);
    const postalCodes = useAppSelector((state) => state.postalCodes.all);
    const postalCodeCount = useAppSelector((state) => state.postalCodes.totalResources);
    const { singleZone } = useAppSelector((state) => state.zones);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.auth.accessToken);
    const [mapCenter, setMapCenter] = useState([43.651070, -79.347015]);
    const zipCodes: any = [];
    useEffect(() => {
        setUiData({
            ...uiData,
            countries: {
                ...uiData.countries,
                data: countries,
                loading: false
            },
            states: {
                ...uiData,
                data: states,
                loading: false,
            }
        });
        if (singleZone) {
            const countryName: any = countries.filter((item: any) => item.value === singleZone?.address?.country);
            const stateName: any = states.filter((item: any) => item.value === singleZone?.address?.state);
            singleZone?.zipCodes?.map((zipCode: string) => zipCodes.push({
                value: zipCode,
                label: zipCode
            }));
            setFormInputs({
                ...formInputs,
                name: singleZone?.name,
                street: singleZone?.address?.street,
                city: singleZone?.address?.city,
                state: {
                    label: stateName?.[0]?.label ?? "",
                    value: singleZone?.address?.state
                },
                zipCode: singleZone?.address?.zipCode,
                country: {
                    value: singleZone?.address?.country,
                    label: countryName?.[0]?.label ?? ""
                },
                zoneID: singleZone?.zoneID,
                zipCodes: zipCodes,
                serviceFees: singleZone?.serviceFees?.value ?? 0,
                tip: singleZone?.tip?.value ?? 0,
                deliveryCharges: singleZone?.deliveryCharges?.value ?? 0,
                minimumOrderAmount: singleZone?.minimumOrderAmount ?? 0,
                minimumOrderAmountWithFee: singleZone?.minimumOrderAmountWithFee ?? 0,
                driverPerTripPayout: singleZone?.driverPerTripPayout?.value ?? 0
            });
        }

    }, [singleZone]);

    useEffect(() => {
        if (id) {
            setEditMode(true);
            if (!token) return;
            dispatch(getZone({ id, token }));
        } else {
            setEditMode(false);
            setFormInputs(initialValues);
        }
    }, [id])

    const handleFormSubmit = (data: any, resetForm: () => void): void => {
        if (!token) return;
        const finalData: Object = {};
        Object.assign(finalData, {
            city: data?.city,
            zoneID: data?.zoneID,
            country: data?.country.value,
            name: data?.name,
            state: data?.state.value,
            street: data?.street,
            zipCode: data?.zipCode,
            serviceFees: data?.serviceFees,
            deliveryCharges: data?.deliveryCharges,
            tip: data?.tip,
            minimumOrderAmount: data?.minimumOrderAmount,
            minimumOrderAmountWithFee: data?.minimumOrderAmountWithFee,
            driverPerTripPayout: data?.driverPerTripPayout,
        });
        let ClusterCode: any = [];
        data.zipCodes.map((zipCodes: any) => { ClusterCode.push(zipCodes.value) });
        Object.assign(finalData, {
            zipCodes: ClusterCode,
        });
        if (editMode && id) {
            dispatch(updateZone({ id, data: finalData, token })).then((data) => {
                if (data.type === "zone/update/fulfilled") { navigate("/zones"); }
            })
        } else {
            dispatch(storeZone({ data: finalData, token })).then((data) => {
                if (data.type === "zone/store/fulfilled") { navigate("/zones"); }
            })
        }
    }

    const fetchZipCodes = (values: any) => {
        setFormInputs(values);
        const params = {
            country: values?.country?.value,
            state: values.state?.value,
            city: values.city,
            zipCode: values.zipCode
        };
        setUiData({
            ...uiData, postalCodes: {
                ...uiData,
                loading: true
            }
        });
        dispatch(getPostalCode({ params })).then(() => {
            setUiData({
                ...uiData, postalCodes: {
                    ...uiData.postalCodes,
                    loading: false
                }
            });
        });
    }
    useEffect(() => {
        console.log(mapCenter)
    }, [pointsData, mapCenter])
    const addAllCodes = (values: any) => {
        console.log("dddd")
        if (postalCodeCount) {
            if (postalCodeCount >= 15000) {
                dispatch(errorAlert("Max length for postal code exceeded. Please specify appropriate address."));
                return false;
            }
        }
        setFormInputs(values);
        if (values?.country?.value !== undefined && values?.country?.value !== '' && values.state?.value !== undefined && values.state?.value !== '' || (values.city !== undefined && values.city !== '') && postalCodeCount !== undefined && postalCodeCount > 0) {
            setUiData({
                ...uiData,
                postalCodes: {
                    ...uiData.postalCodes,
                    fetching: true,
                    isAdded: true,
                }
            });
            const params = {
                country: values.country.value,
                state: values.state.value,
                city: values.city,
                zipCode: values.zipCode,
                documentLimit: postalCodeCount
            };
            console.log("dddd 2")
            dispatch(getPostalCode({ params })).then((response: any) => {
                if (response.type === 'postal-code/get/fulfilled') {
                    const responseData = response?.payload?.results;
                    if (responseData.length !== 0) {
                        setFormInputs({ ...formInputs, zipCodes: responseData?.map((postalCodes: any) => ({ label: postalCodes.zipCode, value: postalCodes.zipCode })) });
                        setPointsData(responseData?.map((postalCodes: any) => ({ lat: postalCodes.lat, lng: postalCodes.lng })));
                        setMapCenter(responseData?.map((postalCodes: any) => ([postalCodes.lat, postalCodes.lng]))?.[0])
                    }
                }
                setUiData({
                    ...uiData,
                    postalCodes: {
                        ...uiData.postalCodes,
                        fetching: false,
                        isAdded: false,
                    }
                })
            });
        } else {
            if (values?.country?.value === undefined || values?.country?.value === '') {
                dispatch(errorAlert("Country is required"));
            }
            if (values?.state?.value === undefined || values?.state?.value === '') {
                dispatch(errorAlert("State is required"));
            }
        }
    }
    return (
        <MainLayout isSearchable={false}>
            <main className="main users chart-page">
                <div className="container-fluid">
                    <h2 className="main-title"> {editMode ? "Edit Delivery Zone" : "Add Delivery Zone"}</h2>
                    <Row className="">
                        <Col md={8}>
                            <Formik
                                validationSchema={validationSchema}
                                enableReinitialize={true}
                                onSubmit={(values, { resetForm }) => {
                                    handleFormSubmit(values, resetForm)
                                }}
                                initialValues={formInputs}
                                validateOnBlur={false}
                                validateOnChange={false}
                            >
                                {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <fieldset >
                                            <legend>Zone Details:</legend>
                                            <Row className="fieldset-row" >
                                                <Col md={12}>
                                                    <Form.Label htmlFor="name">Zone Name</Form.Label>
                                                    <Form.Control id="name" type="text" placeholder="" name="name" value={values.name}
                                                        onChange={handleChange} isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.name}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={12} className="mb-2">
                                                    <Form.Label htmlFor="zoneID">Zone ID</Form.Label>
                                                    <Form.Control id="zoneID" type="text" placeholder="" name="zoneID" value={values.zoneID}
                                                        onChange={handleChange} isValid={touched.zoneID && !errors.zoneID} isInvalid={!!errors.zoneID} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.zoneID}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={6} className="mb-2">
                                                    <Form.Label htmlFor="deliveryCharges" >Delivery Charges (C$)</Form.Label>
                                                    <Form.Control id="deliveryCharges" type="number" placeholder="" name="deliveryCharges" value={values.deliveryCharges}
                                                        onChange={handleChange} isValid={touched.deliveryCharges && !errors.deliveryCharges} isInvalid={!!errors.deliveryCharges} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.deliveryCharges}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={6} className="mb-2">
                                                    <Form.Label htmlFor="serviceFees" >Service Fees (C$)</Form.Label>
                                                    <Form.Control id="serviceFees" type="number" placeholder="" name="serviceFees" value={values.serviceFees}
                                                        onChange={handleChange} isValid={touched.serviceFees && !errors.serviceFees} isInvalid={!!errors.serviceFees} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.serviceFees}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={6} className="mb-2">
                                                    <Form.Label htmlFor="minimumOrderAmountWithFee">Min. order amount with fee(C$)</Form.Label>
                                                    <Form.Control id="minimumOrderAmountWithFee" type="number" placeholder="" name="minimumOrderAmountWithFee" value={values.minimumOrderAmountWithFee}
                                                        onChange={handleChange} isValid={touched.minimumOrderAmountWithFee && !errors.minimumOrderAmountWithFee} isInvalid={!!errors.minimumOrderAmountWithFee} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.minimumOrderAmountWithFee}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={6} className="mb-2">
                                                    <Form.Label htmlFor="minimumOrderAmount">Min. order amount without fee(C$)</Form.Label>
                                                    <Form.Control id="minimumOrderAmount" type="number" placeholder="" name="minimumOrderAmount" value={values.minimumOrderAmount}
                                                        onChange={handleChange} isValid={touched.minimumOrderAmount && !errors.minimumOrderAmount} isInvalid={!!errors.minimumOrderAmount} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.minimumOrderAmount}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                 
                                                <Col md={6} className="mb-2">
                                                    <Form.Label htmlFor="driverPerTripPayout">Drivers Payout <small>Per/Order</small> (C$)</Form.Label>
                                                    <Form.Control id="driverPerTripPayout" type="number" placeholder="" name="driverPerTripPayout" value={values.driverPerTripPayout}
                                                        onChange={handleChange} isValid={touched.driverPerTripPayout && !errors.driverPerTripPayout} isInvalid={!!errors.driverPerTripPayout} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.driverPerTripPayout}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={6} className="mb-2">
                                                    <Form.Label htmlFor="tip">Tip For Drivers (C$)</Form.Label>
                                                    <Form.Control id="tip" type="number" placeholder="" name="tip" value={values.tip}
                                                        onChange={handleChange} isValid={touched.tip && !errors.tip} isInvalid={!!errors.tip} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.tip}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                

                                                <Form.Label htmlFor="address">Address</Form.Label>
                                                <Col md={4} className="mb-2">
                                                    <Select
                                                        className={touched.country && !errors.country ? "basic-single is-invalid" : "border-danger"}
                                                        id="country"
                                                        classNamePrefix="select"
                                                        defaultValue={[]}
                                                        isLoading={uiData?.countries?.loading}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        name="country"
                                                        isMulti={false}
                                                        options={uiData?.countries?.data}
                                                        value={values.country}
                                                        onChange={(selectedOption: any) => setFieldValue("country", selectedOption)}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: !!errors.country ? '#dc3545' : '#ced4da',
                                                            }),
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className={!!errors.country?.value ? "d-block" : ""}>
                                                        {errors.country?.value}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={4} className="mb-2">
                                                    <Select
                                                        className={touched.state && !errors.state ? "basic-single is-invalid" : "border-danger"}
                                                        id="state"
                                                        classNamePrefix="select"
                                                        defaultValue={[]}
                                                        isLoading={uiData?.states?.loading}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        name="state"
                                                        isMulti={false}
                                                        options={uiData?.states?.data}
                                                        value={values.state}
                                                        onChange={(selectedOption: any) => setFieldValue("state", selectedOption)}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: !!errors.state ? '#dc3545' : '#ced4da',
                                                            }),
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className={!!errors.state?.value ? "d-block" : ""}>
                                                        {errors.state?.value}
                                                    </Form.Control.Feedback>

                                                </Col>
                                                <Col md={4} className="mb-2">
                                                    <Form.Control id="city" placeholder="City"
                                                        type="text" name="city" value={values.city}
                                                        onChange={handleChange} isValid={touched.city && !errors.city} isInvalid={!!errors.city} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.city}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={4} className="mb-2">
                                                    <Form.Control id="zipCode" placeholder="Zipcode" type="text" name="zipCode" value={values.zipCode}
                                                        onChange={handleChange} isValid={touched.zipCode && !errors.zipCode} isInvalid={!!errors.zipCode} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.zipCode}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={4} className="mb-2">
                                                    <Form.Control id="street" placeholder="Street"
                                                        type="text" name="street" value={values.street}
                                                        onChange={handleChange} isValid={touched.street && !errors.street} isInvalid={!!errors.street} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.street}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={4} className="mb-2">
                                                    <div className="d-flex gap-3">
                                                        <button className="btn btn-info" type="button" onClick={(e) => fetchZipCodes(values)}>Fetch zipcode</button>
                                                        {postalCodeCount !== undefined && postalCodeCount > 0 ?
                                                            <Button type="button" variant="primary" disabled={uiData?.postalCodes?.fetching ? true : false} onClick={(e) => addAllCodes(values)} >
                                                                {uiData?.postalCodes?.fetching ? <Spinner animation="grow" size="sm" role="status" aria-hidden="true" />
                                                                    : "Add zipcode"}
                                                            </Button>
                                                            : null}
                                                    </div>
                                                    <div className="mb-0">
                                                        {uiData?.postalCodes?.loading ?
                                                            <>
                                                                <Spinner animation="grow" size="sm" />
                                                                <small className="ms-2">Loading...</small>
                                                            </> :
                                                            <>
                                                                <b>{postalCodeCount}</b>
                                                                <small className="ms-2">Postal Code Found</small>
                                                            </>
                                                        }
                                                    </div>
                                                </Col>

                                                <Col md={12} className="mb-2">
                                                    <Form.Label htmlFor="zipCodes" >Zip Codes</Form.Label>
                                                    <Select
                                                        className="basic-single"
                                                        id="zipCodes"
                                                        classNamePrefix="select"
                                                        defaultValue={[]}
                                                        isLoading={uiData.postalCodes?.isAdded}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        name="zipCodes"
                                                        isMulti={true}
                                                        options={postalCodes.map((postal_code) => ({ ...postal_code, label: postal_code.zipCode, value: postal_code.zipCode }))}
                                                        value={values.zipCodes}
                                                        onChange={(selectedOption) => setFieldValue("zipCodes", selectedOption.map((option: any) => option))}
                                                        styles={{ valueContainer: styles => ({ ...styles, maxHeight: '300px', overflowY: 'scroll' }), }}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className={!!errors.zipCodes ? "d-block" : ""}>
                                                        {errors.zipCodes}
                                                    </Form.Control.Feedback>
                                                </Col>

                                            </Row>
                                        </fieldset>
                                        <div className="mt-1 mb-5">
                                            <button className="btn btn-info" type="submit">{editMode ? "Update" : "Submit"}</button>
                                        </div>
                                    </Form>
                                )}
                            </Formik >
                        </Col>
                        <Col md={4}>
                            <LeafletMap
                                center={mapCenter}
                                zoom={5}
                                markers={pointsData}
                            />
                        </Col>
                    </Row>
                </div>
            </main >
        </MainLayout >
    )
}

export default Zone;