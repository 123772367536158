import { Props } from "../../types";
import MainLayout from "../../components/layouts/MainLayout";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { Col, Row, } from "react-bootstrap";
import useTitle from "../../hooks/useTitle";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Select from 'react-select';
import axios from "axios";
import { getCluster, storeCluster, updateCluster } from "../../store/cluster/clusterSlice";
import { countries, states } from "../../config/constants";
function Cluster(props: Props) {
    useTitle("Create Cluster");
    const initialValues = {
        name: "",
        street: "",
        city: "",
        zipCode: "",
        state: {
            value: "AB",
            label: "Alberta"
        },
        country: {
            value: "CA",
            label: "Canada"
        },
        clusterID: "",
    }
    const [formInputs, setFormInputs] = useState(initialValues);
    const validationSchema = yup.object().shape({
        name: yup.string().required("Cluster name is a required field."),
        country: yup.object({
            value: yup.string().required("Country is a required field."),
            label: yup.string().required("Country is a required field.")
        }),
        state: yup.object({
            value: yup.string().required("State is a required field."),
            label: yup.string().required("State is a required field.")
        }),
        city: yup.string().required("City is a required field."),
        street: yup.string(),
        zipCode: yup.string(),
        clusterID: yup.string().required("Cluster ID is a required field."),
    });

    const { id } = useParams();
    const [editMode, setEditMode] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.auth.accessToken);
    const { singleCluster } = useAppSelector((state) => state.clusters);


    const [uiData, setUiData] = useState<any>({
        countries: {
            loading: true,
            data: []
        },
        states: {
            loading: true,
            data: [],
        }
    })
    useEffect(() => {
        if (singleCluster) {
            const countryName: any = countries.filter((item: any) => item.value === singleCluster?.address?.country);
            const stateName: any = states.filter((item: any) => item.value === singleCluster?.address?.state);
            setFormInputs({
                ...formInputs,
                name: singleCluster?.name,
                street: singleCluster?.address?.street,
                city: singleCluster?.address?.city,
                state: {
                    value: singleCluster?.address?.state,
                    label: stateName?.[0]?.label ?? ""
                },
                zipCode: singleCluster?.address?.zipCode,
                country: {
                    value: singleCluster?.address?.country,
                    label: countryName?.[0]?.label ?? ""
                },
                clusterID: singleCluster?.clusterID,
            });
        }
        setUiData({
            ...uiData,
            countries: {
                ...uiData.countries,
                data: countries,
                loading: false
            },
            states: {
                ...uiData,
                data: states,
                loading: false,
            }
        });
    }, [singleCluster]);

    useEffect(() => {
        if (id) {
            setEditMode(true);
            if (!token) return;
            dispatch(getCluster({ id, token }));
        } else {
            setEditMode(false);
            setFormInputs(initialValues)
        }
    }, [id])

    const handleFormSubmit = (data: any, resetForm: () => void): void => {
        if (!token) return;
        const finalData: Object = {};
        Object.assign(finalData, {
            city: data.city,
            clusterID: data.clusterID,
            country: data.country.value,
            name: data.name,
            state: data.state.value,
            street: data.street,
            zipCode: data.zipCode
        });
        if (editMode && id) {
            dispatch(updateCluster({ data: finalData, id, token })).then((data) => {
                navigate("/clusters");
            })
        } else {
            dispatch(storeCluster({ data: finalData, token })).then((data) => {
                navigate("/clusters");
            })
        }
    }
    return (
        <MainLayout isSearchable={false}>
            <main className="main users chart-page">
                <div className="container-fluid">
                    <h2 className="main-title"> {editMode ? "Edit Pickup Cluster" : "Add Pickup Cluster"}</h2>
                    <Row className="justify-content-center">
                        <Col md={8}>
                            <Formik
                                validationSchema={validationSchema}
                                enableReinitialize={true}
                                onSubmit={(values, { resetForm }) => {
                                    handleFormSubmit(values, resetForm)
                                }}
                                initialValues={formInputs}
                                validateOnBlur={false}
                                validateOnChange={false}
                            >
                                {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <fieldset className="auth-fieldset">
                                            <legend>Cluster Details:</legend>
                                            <Row className="fieldset-row" >
                                                <Col md={12}>
                                                    <Form.Label htmlFor="name">Cluster Name</Form.Label>
                                                    <Form.Control id="name" type="text" placeholder="" name="name" value={values.name}
                                                        onChange={handleChange} isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.name}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Label htmlFor="clusterID">Cluster ID</Form.Label>
                                                    <Form.Control id="clusterID" type="text" placeholder="" name="clusterID" value={values.clusterID}
                                                        onChange={handleChange} isValid={touched.clusterID && !errors.clusterID} isInvalid={!!errors.clusterID} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.clusterID}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Form.Label htmlFor="address">Address</Form.Label>
                                                <Col md={8} className="mb-2">
                                                    <Form.Control id="street" placeholder="Street"
                                                        type="text" name="street" value={values.street}
                                                        onChange={handleChange} isValid={touched.street && !errors.street} isInvalid={!!errors.street} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.street}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={4} className="mb-2">
                                                    <Form.Control id="city" placeholder="City"
                                                        type="text" name="city" value={values.city}
                                                        onChange={handleChange} isValid={touched.city && !errors.city} isInvalid={!!errors.city} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.city}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={4} className="mb-2">
                                                    <Select
                                                        className={touched.state && !errors.state ? "basic-single is-invalid" : "border-danger"}
                                                        id="state"
                                                        classNamePrefix="select"
                                                        defaultValue={[]}
                                                        isLoading={uiData?.states?.loading}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        name="state"
                                                        isMulti={false}
                                                        options={uiData?.states?.data}
                                                        value={values.state}
                                                        onChange={(selectedOption: any) => setFieldValue("state", selectedOption)}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: !!errors.state ? '#dc3545' : '#ced4da',
                                                            }),
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className={!!errors.state?.value ? "d-block" : ""}>
                                                        {errors.state?.value}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={4} className="mb-2">
                                                    <Form.Control id="zipCode" placeholder="Zipcode" type="text" name="zipCode" value={values.zipCode}
                                                        onChange={handleChange} isValid={touched.zipCode && !errors.zipCode} isInvalid={!!errors.zipCode} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.zipCode}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={4} className="mb-2">
                                                    <Select
                                                        className={touched.country && !errors.country ? "basic-single is-invalid" : "border-danger"}
                                                        id="country"
                                                        classNamePrefix="select"
                                                        defaultValue={[]}
                                                        isLoading={uiData?.countries?.loading}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        name="country"
                                                        isMulti={false}
                                                        options={uiData?.countries?.data}
                                                        value={values.country}
                                                        onChange={(selectedOption: any) => setFieldValue("country", selectedOption)}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: !!errors.country ? '#dc3545' : '#ced4da',
                                                            }),
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className={!!errors.country?.value ? "d-block" : ""}>
                                                        {errors.country?.value}
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Row>
                                        </fieldset>
                                        <div className="mt-1 mb-5">
                                            <button className="btn btn-info" type="submit">{editMode ? "Update" : "Submit"} </button>
                                        </div>
                                    </Form>
                                )
                                }
                            </Formik >
                        </Col>
                    </Row>
                </div>
            </main >
        </MainLayout >
    )
}

export default Cluster;