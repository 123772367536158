import { Props } from "../../types";
import MainLayout from "../../components/layouts/MainLayout";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { Col, Row } from "react-bootstrap";
import useTitle from "../../hooks/useTitle";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import moment from "moment";
import { getOffer, storeOffer, updateOffer } from "../../store/offer/offerSlice";
import { Formik, ErrorMessage } from "formik";

function Offer(props: Props) {
    useTitle("Create Offer");
    const initialValues = {
        name: "",
        code: "",
        description: "",
        value: "",
        cartValue: "",
        quantity: "",
        validTo: "",
        image: "",
        maxDiscount: ""
    }
    const [formInputs, setFormInputs] = useState(initialValues);
    const [offerImage, setOfferImage] = useState<File | null>(null);
    const validationSchema = yup.object().shape({
        name: yup.string().required("Name is a required field."),
        code: yup.string().min(6, "Coupon code min length is 6").matches(/[A-Z0-9&_\.-]{6}/, 'Coupon code should be uppercase letter and some special charater is valid like &,_').required("Code is a required field."),
        description: yup.string(),
        value: yup.number().min(1, 'Min coupon value is 1%').required("Coupon value is required"),
        cartValue: yup.number().min(1, 'Min. Order Amount value is 1 C$').required("Min. Order Amount is required"),
        quantity: yup.number().min(1, 'Min total quantity is 1').required("Total quantity is required"),
        validTo: yup.date().required("Valid to is required from."),
        maxDiscount: yup.number().min(1, 'Max discount is 1').required("Max discount is required"),
    });

    const { id } = useParams();
    const [editMode, setEditMode] = useState(false);
    const { singleOffer } = useAppSelector((state) => state.offers);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.auth.accessToken);
    useEffect(() => {
        if (id) {
            setEditMode(true);
            if (!token) return;
            dispatch(getOffer({ id, token }))
        } else {
            setEditMode(false);
            setFormInputs(initialValues);
        }
    }, [id]);
    useEffect(() => {
        if (singleOffer && editMode) {
            setFormInputs({
                ...formInputs,
                name: singleOffer?.name,
                code: singleOffer?.code,
                description: singleOffer?.description,
                value: singleOffer?.value,
                cartValue: singleOffer?.cartValue,
                quantity: singleOffer?.quantity,
                validTo: moment(singleOffer?.validTo).utcOffset(0, true).format("YYYY-MM-DD"),
                maxDiscount: singleOffer?.maxDiscount,
                image: ""
            });
        }
    }, [singleOffer])

    const handleFormSubmit = (data: any, resetForm: () => void): void => {
        if (!token) return;
        const formData = new FormData();
        formData.append("name", data?.name);
        formData.append("description", data?.description);
        formData.append("code", data?.code);
        formData.append("cartValue", data?.cartValue);
        formData.append("value", data?.value);
        formData.append("quantity", data?.quantity)
        formData.append("validTo", moment(data?.validTo).utcOffset(0, true).format("YYYY-MM-DD"));
        formData.append("maxDiscount", data?.maxDiscount);
        formData.append("priceType", "percent");
        formData.append("type", "flat-off");
        formData.append('image', offerImage as Blob);
        if (editMode && id) {
            dispatch(updateOffer({ id, data: formData, token })).then((data) => {
                if (data.type === "offer/update/fulfilled") {
                    resetForm();
                    navigate("/offers");
                }
            })
        } else {
            dispatch(storeOffer({ data: formData, token })).then((data) => {
                if (data.type === "offer/store/fulfilled") {
                    resetForm();
                    navigate("/offers");
                }
            })
        }
    }
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files && event.currentTarget.files[0];
        if (file) {
            setOfferImage(file);
            console.log(file)
        }
    };
    return (
        <MainLayout isSearchable={false}>
            <main className="main users chart-page">
                <div className="container-fluid">
                    <h2 className="main-title"> {editMode ? "Edit Offer" : "Add Offer"}</h2>
                    <Row className="justify-content-center">
                        <Col md={8}>
                            <Formik
                                validationSchema={validationSchema}
                                enableReinitialize={true}
                                onSubmit={(values, { resetForm }) => {

                                    handleFormSubmit(values, resetForm)
                                }}
                                initialValues={formInputs}
                                validateOnBlur={false}
                                validateOnChange={false}
                            >
                                {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <> <Row className="fieldset-row" >
                                            <Col md={6}>
                                                <Form.Label htmlFor="name">Offer Title</Form.Label>
                                                <Form.Control id="name" type="text" placeholder="" name="name" value={values.name}
                                                    onChange={handleChange} isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col md={6} className="mb-2">
                                                <Form.Label htmlFor="code" >Coupon Code</Form.Label>
                                                <Form.Control id="code" type="text" placeholder="" name="code" value={values.code}
                                                    onChange={handleChange} isValid={touched.code && !errors.code} isInvalid={!!errors.code} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.code}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col md={12} className="mb-2">
                                                <Form.Label htmlFor="description">Description</Form.Label>
                                                <Form.Control id="description" type="text" placeholder="" name="description" value={values.description}
                                                    onChange={handleChange} isValid={touched.description && !errors.description} isInvalid={!!errors.description} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.description}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col md={4} className="mb-2">
                                                <Form.Label htmlFor="value" >Discount Value (%)</Form.Label>
                                                <Form.Control id="value" type="number" placeholder="" name="value" value={values.value}
                                                    onChange={handleChange} isValid={touched.value && !errors.value} isInvalid={!!errors.value} min={1} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.value}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col md={4} className="mb-2">
                                                <Form.Label htmlFor="cartValue">Min. Order Amount (C$)</Form.Label>
                                                <Form.Control id="cartValue" type="number" placeholder="" name="cartValue" value={values.cartValue}
                                                    onChange={handleChange} isValid={touched.cartValue && !errors.cartValue} isInvalid={!!errors.cartValue} min={1} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.cartValue}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col md={4} className="mb-2">
                                                <Form.Label htmlFor="maxDiscount">Max Discount</Form.Label>
                                                <Form.Control id="maxDiscount" type="number" placeholder="" name="maxDiscount" value={values.maxDiscount}
                                                    onChange={handleChange} isValid={touched.maxDiscount && !errors.maxDiscount} isInvalid={!!errors.maxDiscount} min={1} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.maxDiscount}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col md={4} className="mb-2">
                                                <Form.Label htmlFor="quantity">Redeem Count</Form.Label>
                                                <Form.Control id="quantity" type="number" placeholder="" name="quantity" value={values.quantity}
                                                    onChange={handleChange} isValid={touched.quantity && !errors.quantity} isInvalid={!!errors.quantity} min={1} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.quantity}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col md={4} className="mb-2">
                                                <Form.Label htmlFor="validTo">Valid Till Date</Form.Label>
                                                <Form.Control id="validTo" type="date" placeholder="" name="validTo" value={values.validTo}
                                                    onChange={handleChange} isValid={touched.validTo && !errors.validTo} isInvalid={!!errors.validTo} min={moment().format('YYYY-MM-DD')} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.validTo}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col md={4} className="mb-2">
                                                <Form.Label htmlFor="validTo">Offer Image</Form.Label>
                                                <input type="file" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleFileChange(event);
                                                }} />
                                                <ErrorMessage name="image" component="div" className="text-danger" />
                                            </Col>
                                        </Row>
                                            <div className="mt-1 mb-5">
                                                <button className="btn btn-info" type="submit">{editMode ? "Update" : "Submit"}</button>
                                            </div>
                                        </>
                                    </Form>
                                )}
                            </Formik >
                        </Col>
                    </Row>
                </div>
            </main >
        </MainLayout >
    )
}

export default Offer;