import { Props } from "../../types";
import MainLayout from "../../components/layouts/MainLayout";
import ThemeTable, { Column } from "../../components/ui-components/table/ThemeTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { useEffect, useState } from "react";
import { NoDataFound } from "../../components/ui-components/table/ThemeTable";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import * as formik from 'formik';
import * as yup from 'yup';
import Swal from "sweetalert2";
import { DELETE_ALERT_PROMPT_CONFIG } from "../../config/constants";
import { destroyInstruction, getInstructions, storeInstruction, updateInstruction } from "../../store/instruction/instructionSlice";
import { formateDate } from "../../helper/basic";
function Instructions(props: Props) {
    const columns: Array<Column> = [
        { label: "Name", accessor: "name" },
        { label: "Created At", accessor: "created_at" },
        { label: "Actions", accessor: "actions" },
    ];

    const initialValues = {
        name: '',
    }
    const [inputs, setInputs] = useState(initialValues)
    const { Formik } = formik;
    const validationSchema = yup.object().shape({
        name: yup.string().required("Instruction is a required field."),
    });
    const dispatch = useAppDispatch();
    const [pageNumber, setPageNumber] = useState(1);
    const [query, setQuery] = useState("");
    const initialHeaders: { query: string; pageNumber: number } = {
        query: query,
        pageNumber: pageNumber,
    }
    const [apiHeaders, setApiHeaders] = useState(initialHeaders);
    const instructions = useAppSelector((state) => state.instruction.all);
    const token = useAppSelector((state) => state.auth.accessToken);
    const [modalShow, setModalShow] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [id, setID] = useState("");
    useEffect(() => {
        if (!token) return;
        dispatch(getInstructions({ params: apiHeaders, token }));
    }, []);
    const onEdit = (id: string) => {
        setID(id);
        setModalShow(true);
        setEditMode(true);
        const singleInstructions = instructions.find(item => item._id === id);
        setInputs({
            ...inputs,
            name: singleInstructions?.name
        });
    }

    const onCreate = () => {
        setInputs(initialValues);
        setEditMode(false);
        setModalShow(true);
    }
    const onHide = () => {
        setModalShow(false);
        setEditMode(false);
        setInputs(initialValues);
    }
    const handleFormSubmit = (data: any, resetForm: () => void): void => {
        if (!token) return;
        /**
         * Create Mode
         */
        if (!editMode) {
            dispatch(storeInstruction({ data, token })).then(() => {
                dispatch(getInstructions({ params: apiHeaders, token }));
                setModalShow(false);
                resetForm();
            })
        } else {
            /***
             * Edit Mode
             */
            dispatch(updateInstruction({ data: data, id: id, token: token })).then(() => {
                dispatch(getInstructions({ params: apiHeaders, token }));
                setModalShow(false);
                resetForm();
            })
        }
    }
    const onDelete = (id: string) => {
        if (!token) return;
        Swal.fire(DELETE_ALERT_PROMPT_CONFIG).then((actions) => {
            if (actions.isConfirmed) {
                if (!token) return;
                dispatch(destroyInstruction({ id, token }))
            }
        });
    }
    return (
        <MainLayout isSearchable={false}>
            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="slot-modal-vcenter"
                centered
                onHide={() => onHide()}
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="slot-modal-vcenter" as="h5">
                        {editMode ? "Edit Instruction" : "Create Instruction"}
                    </Modal.Title>
                </Modal.Header>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={inputs}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => {
                        handleFormSubmit(values, resetForm)
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({ handleSubmit, handleChange, values, touched, errors }) => (
                        <Form noValidate onSubmit={handleSubmit} >
                            <Modal.Body>
                                <Row className="fieldset-row" >
                                    <Col md={12}>
                                        <Form.Label htmlFor='name' className="mb-0">Instruction</Form.Label>
                                        <Form.Control id="name" type='text' placeholder="eg. Tasty Trails" name="name" value={values.name}
                                            onChange={handleChange} isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                                        <Form.Control.Feedback type="invalid" className={!!errors.name ? "d-block" : ""}>
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer className="border-0">
                                <Button type="submit" size="sm">{editMode ? "Update" : "Create"}</Button>
                            </Modal.Footer>
                        </Form>
                    )
                    }
                </Formik >
            </Modal>
            <main className="main users chart-page" id="skip-target">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h2 className="main-title mb-0">Instructions</h2>
                        <Button variant="info" size="sm" onClick={(e) => onCreate()}>
                            Create Instruction
                        </Button>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <ThemeTable columns={columns}>
                                <tbody>
                                    {instructions && instructions.map((instruction: any) => {
                                        return (
                                            <tr key={instruction?._id}>
                                                <td>
                                                    <p className="m-0">
                                                        <h6>{instruction?.name}</h6>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        {formateDate(instruction?.createdAt)}
                                                    </p>
                                                </td>
                                                <td style={{ width: "20%" }}>
                                                    <div className="d-flex gap-2">
                                                        <Button size="sm" variant="info" className="rounded-pill ps-3 pe-3" onClick={(e) => onEdit(instruction?._id)}>
                                                            Edit
                                                        </Button>
                                                        <Button size="sm" variant="danger" className="rounded-pill ps-3 pe-3" onClick={(e) => onDelete(instruction?._id)}>
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {(instructions && instructions.length !== 0) ? null : <NoDataFound colSpan={5} />}
                                </tbody>
                            </ThemeTable>
                        </div>
                    </div>
                </div>
            </main>
        </MainLayout >
    )
}

export default Instructions;