import MainLayout from "../components/layouts/MainLayout";
import StateCards from "../components/ui-components/stat-cards/StatCards";
import "./Dashboard.css";
import { Props } from "../types";

function Profile(props: Props) {
    return (
        <MainLayout isSearchable={false}>
            <main className="main users chart-page" id="skip-target">
                <div className="container-fluid">
                    <h2 className="main-title">Profile</h2>


                </div>
            </main>
        </MainLayout>
    )
}

export default Profile;