import { Props } from "../../types";
import MainLayout from "../../components/layouts/MainLayout";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { Col, InputGroup, Row, } from "react-bootstrap";
import useTitle from "../../hooks/useTitle";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Select from 'react-select';
import axios from "axios";
import { getCluster, getClusters, storeCluster, updateCluster } from "../../store/cluster/clusterSlice";
import { getUser, updateUser } from "../../store/user/userSlice";
import { genders } from "../../config/constants";
import { getDriver, updateDriver } from "../../store/driver/driverSlice";
import { getZones } from "../../store/zone/zoneSlice";
import { GroupBase } from "react-select";

const vehicleTypes: any[] = [
    { label: "Car", value: "Car" },
    { label: "Bike", value: "Bike" },
    { label: "Truck", value: "Truck" },
    { label: "Van", value: "Van" },
];

const residentialStatus: any[] = [
    { label: "Citizenship", value: "Citizenship" },
    { label: "Work Permit", value: "Work Permit" },
    { label: "Permanent Residence", value: "Permanent Residence" },
];

function Driver(props: Props) {
    useTitle("Edit Driver");
    const [initialValues, setInitialValues] = useState({
        firstName: "",
        lastName: "",
        gender: {
            value: "",
            label: "-Please Select-"
        },
        birthday: "",
        isVerified: false,
        isActivated: false,
        isAvailable: false,
        phoneNumber: "",
        dialCode: "",
        email: "",
        clusterID: {
            value: "",
            label: "-Please Select-"
        },
        zoneID: {
            value: "",
            label: "-Please Select-"
        },
        drivingLicenseImage: "",
        vehicleIdentificationNumber: "",
        vehicleInsuranceImage: "",
        vehicleRegistrationCertificateImage: "",
        vehicleRegistrationPlate: "",
        vehicleType: {},
        residentialStatus: {},
        socialInsuranceNumber: ""
    });

    const validationSchema = yup.object().shape({
        firstName: yup.string().required("First name is a required field."),
        lastName: yup.string().required("Last name is a required field."),
        email: yup.string(),
        gender: yup.object().shape({
            value: yup.string().required("Gender is a required field."),
            label: yup.string().required("Gender is a required field.")
        }),
        birthday: yup.string().matches(/^\d{4}-(0[1-9]|1[0-2])-([0-2]\d|3[01])$/, 'Date of birth should be in mm/dd/yyyy').required("Birthday is a required field."),
        isVerified: yup.bool().oneOf([true, false], 'Field must be checked'),
        isActivated: yup.bool().oneOf([true, false], 'Field must be checked'),
        isAvailable: yup.bool().oneOf([true, false], 'Field must be checked'),
        phoneNumber: yup.string().required("First name is a required field."),
        dialCode: yup.string().required("Last name is a required field."),
        clusterID: yup.object().shape({
            label: yup.string().required("Cluster is a required field."),
            value: yup.string().required("Cluster is a required field.")
        }),
        zoneID: yup.object().shape({
            label: yup.string().required("Zone is a required field."),
            value: yup.string().required("Zone is a required field.")
        })
    });

    const { id } = useParams();
    const [editMode, setEditMode] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.auth.accessToken);
    const { singleDriver } = useAppSelector((state) => state.drivers);
    const clustersState = useAppSelector((state) => state.clusters.all);
    const zonesState = useAppSelector((state) => state.zones.all);
    const [clusters, setClusters] = useState([]);
    const [zones, setZones] = useState([]);
    useEffect(() => {
        if (singleDriver) {
            const selectedCluster: any[] = clusters?.filter((cluster: any) => cluster?._id === singleDriver?.clusterID)
            const selectedZone: any[] = zones?.filter((zone: any) => zone?._id === singleDriver?.zoneID);
            const selectedVehicleType = vehicleTypes.filter((data: any) => data.value === singleDriver?.vehicleDetails?.vehicleType);
            const selectedResidentialStatus = residentialStatus.filter((data: any) => data.value === singleDriver?.residentialStatus);
            setInitialValues({
                ...initialValues,
                firstName: singleDriver?.firstName,
                lastName: singleDriver?.lastName,
                email: singleDriver?.email,
                phoneNumber: singleDriver?.phoneNumber,
                dialCode: singleDriver?.dialCode,
                isActivated: singleDriver?.isActivated,
                isVerified: singleDriver?.isVerified,
                isAvailable: singleDriver?.isAvailable,
                birthday: singleDriver?.birthday,
                gender: {
                    label: singleDriver?.gender,
                    value: singleDriver?.gender
                },
                clusterID: {
                    value: selectedCluster?.[0]?.value ?? "",
                    label: selectedCluster?.[0]?.label ?? ""
                },
                zoneID: {
                    value: selectedZone?.[0]?.value ?? "",
                    label: selectedZone?.[0]?.label ?? ""
                },
                drivingLicenseImage: singleDriver?.vehicleDetails?.drivingLicenseImage,
                vehicleRegistrationCertificateImage: singleDriver?.vehicleDetails?.vehicleRegistrationCertificateImage,
                vehicleInsuranceImage: singleDriver?.vehicleDetails?.vehicleInsuranceImage,
                vehicleIdentificationNumber: singleDriver?.vehicleDetails?.vehicleIdentificationNumber,
                vehicleType: selectedVehicleType?.[0],
                vehicleRegistrationPlate: singleDriver?.vehicleDetails?.vehicleRegistrationPlate,
                residentialStatus: selectedResidentialStatus?.[0],
                socialInsuranceNumber: singleDriver?.socialInsuranceNumber,
            });
        }
    }, [singleDriver, clusters, zones]);

    useEffect(() => {
        console.log("DDD")
        const clustersData: any = clustersState?.map((cluster: any) => ({ ...cluster, value: cluster._id, label: `${cluster.name} (${cluster.clusterID})` }));
        const zonesData: any = zonesState?.map((zone: any) => ({ ...zone, value: zone._id, label: `${zone.name} (${zone.zoneID})` }));
        setClusters(clustersData);
        setZones(zonesData);
    }, [clustersState, zonesState])

    useEffect(() => {
        if (id) {
            setEditMode(true);
            if (!token) return;
            dispatch(getDriver({ id, token }));
            dispatch(getClusters({ params: {}, token }));
            dispatch(getZones({ params: {}, token }))
        }
    }, [id])

    const handleFormSubmit = (data: any, resetForm: () => void): void => {
        if (!token) return;
        const finalData: Object = {};
        // drivingLicenseImage: singleDriver?.vehicleDetails?.drivingLicenseImage,
        // vehicleRegistrationCertificateImage: singleDriver?.vehicleDetails?.vehicleRegistrationCertificateImage,
        // vehicleInsuranceImage: singleDriver?.vehicleDetails?.vehicleInsuranceImage,
        Object.assign(finalData, {
            firstName: data?.firstName,
            lastName: data?.lastName,
            birthday: data?.birthday,
            isVerified: data?.isVerified,
            isActivated: data?.isActivated,
            isAvailable: data?.isAvailable,
            gender: data?.gender?.value,
            email: data?.email,
            clusterID: data?.clusterID?.value,
            zoneID: data?.zoneID?.value,
            socialInsuranceNumber: data?.socialInsuranceNumber,
            residentialStatus: data?.residentialStatus?.value,
            vehicleType: data?.vehicleType?.value,
            vehicleIdentificationNumber: data?.vehicleIdentificationNumber,
            vehicleRegistrationPlate: data?.vehicleRegistrationPlate
        });
        if (editMode && id) {
            dispatch(updateDriver({ data: finalData, id, token })).then((data) => {
                navigate("/drivers");
            })
        }
    }
    return (
        <MainLayout isSearchable={false}>
            <main className="main users chart-page">
                <div className="container-fluid">
                    <h2 className="main-title"> {editMode ? "Edit Driver" : "Add Driver"}</h2>
                    <Row className="justify-content-center">
                        <Col md={8}>
                            <Formik
                                validationSchema={validationSchema}
                                enableReinitialize={true}
                                onSubmit={(values, { resetForm }) => {
                                    handleFormSubmit(values, resetForm)
                                }}
                                initialValues={initialValues}
                                validateOnBlur={false}
                                validateOnChange={false}
                            >
                                {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <fieldset >
                                            <legend>Driver Details:</legend>
                                            <Row className="fieldset-row" >
                                                <Col md={6}>
                                                    <Form.Label htmlFor="firstName">First Name</Form.Label>
                                                    <Form.Control id="firstName" type="text" placeholder="" name="firstName" value={values.firstName}
                                                        onChange={handleChange} isValid={touched.firstName && !errors.firstName} isInvalid={!!errors.firstName} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.firstName}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Label htmlFor="lastName">Last Name</Form.Label>
                                                    <Form.Control id="lastName" type="text" placeholder="" name="lastName" value={values.lastName}
                                                        onChange={handleChange} isValid={touched.lastName && !errors.lastName} isInvalid={!!errors.lastName} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.lastName}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Label htmlFor="email">Email</Form.Label>
                                                    <Form.Control id="email" type="text" placeholder="" name="email" value={values.email}
                                                        onChange={handleChange} isValid={touched.email && !errors.email} isInvalid={!!errors.email} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Label htmlFor="phoneNumber">Mobile Number</Form.Label>
                                                    <InputGroup>
                                                        <InputGroup.Text id="dialCode">
                                                            {values.dialCode}
                                                        </InputGroup.Text>
                                                        <Form.Control id="phoneNumber" aria-describedby="dialCode" placeholder="" name="phoneNumber" value={values.phoneNumber} isValid={touched.phoneNumber && !errors.phoneNumber} isInvalid={!!errors.phoneNumber} />
                                                    </InputGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Label htmlFor="birthday">Date Of Birth</Form.Label>
                                                    <Form.Control id="birthday" type="date" placeholder="" name="birthday" value={values.birthday}
                                                        onChange={handleChange} isValid={touched.birthday && !errors.birthday} isInvalid={!!errors.birthday} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.birthday}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Label htmlFor='gender'>Gender</Form.Label>
                                                    <Select
                                                        className="basic-single"
                                                        id='gender'
                                                        classNamePrefix="select"
                                                        defaultValue={[]}
                                                        isClearable={false}
                                                        isSearchable={true}
                                                        isMulti={false}
                                                        name="gender"
                                                        options={genders}
                                                        value={values.gender}
                                                        onChange={(selectedOption: any) => setFieldValue("gender", selectedOption)}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className={!!errors.gender?.value ? "d-block" : ""}>
                                                        {errors.gender?.value}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Label htmlFor='clusterID'>Assign Cluster</Form.Label>
                                                    <Select
                                                        className="basic-single"
                                                        id='clusterID'
                                                        classNamePrefix="select"
                                                        defaultValue={[]}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        name="clusterID"
                                                        options={clusters}
                                                        onChange={(selectedOption: any) => setFieldValue("clusterID", selectedOption)}
                                                        value={values.clusterID}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: !!errors.clusterID ? '#dc3545' : '#ced4da',
                                                            }),
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className={!!errors.clusterID ? "d-block" : ""}>
                                                        {errors.clusterID?.value}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Label htmlFor='zoneID'>Assign Zone</Form.Label>
                                                    <Select
                                                        className="basic-single"
                                                        id='zoneID'
                                                        classNamePrefix="select"
                                                        defaultValue={[]}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        name="zoneID"
                                                        options={zones}
                                                        onChange={(selectedOption: any) => setFieldValue("zoneID", selectedOption)}
                                                        value={values.zoneID}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: !!errors.zoneID ? '#dc3545' : '#ced4da',
                                                            }),
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className={!!errors.zoneID ? "d-block" : ""}>
                                                        {errors.zoneID?.value}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Label htmlFor='isActivated'>Active/Inactivate</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="isActivated"
                                                        label={values.isActivated ? "Active" : "Inactivate"}
                                                        checked={values.isActivated}
                                                        onChange={handleChange}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className={!!errors.isActivated ? "d-block" : ""}>
                                                        {errors.isActivated}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Label htmlFor='isVerified'>Verified/Unverified</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="isVerified"
                                                        label={values.isVerified ? "Verified" : "Unverified"}
                                                        checked={values.isVerified}
                                                        onChange={handleChange}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className={!!errors.isVerified ? "d-block" : ""}>
                                                        {errors.isVerified}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Label htmlFor='isAvailable'>Online/Offline</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="isAvailable"
                                                        label={values.isAvailable ? "Online" : "Offline"}
                                                        checked={values.isAvailable}
                                                        onChange={handleChange}
                                                    />
                                                    <Form.Control.Feedback type="invalid" className={!!errors.isAvailable ? "d-block" : ""}>
                                                        {errors.isAvailable}
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Row>
                                        </fieldset>
                                        <fieldset className="mt-3">
                                            <legend>Residential Details:</legend>
                                            <Row >
                                                <Col md={6}>
                                                    <Form.Label htmlFor="residentialStatus" className="mb-0">
                                                        Residential Status
                                                    </Form.Label>
                                                    <Select
                                                        className="basic-single"
                                                        id='residentialStatus'
                                                        classNamePrefix="select"
                                                        defaultValue={[]}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        name="residentialStatus"
                                                        options={residentialStatus}
                                                        isDisabled={false}
                                                        value={values.residentialStatus}
                                                        onChange={(selectedOption: any) => setFieldValue("residentialStatus", selectedOption)}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: !!errors.residentialStatus ? '#dc3545' : '#ced4da',
                                                            }),
                                                        }}
                                                    />
                                                    {/* <Form.Control.Feedback type="invalid" className={!!errors.residentialStatus?.value ? "d-block" : ""}>
                                                        {errors.residentialStatus?.value}
                                                    </Form.Control.Feedback> */}
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Label htmlFor="socialInsuranceNumber" className="mb-0">
                                                        SIN/SSN
                                                    </Form.Label>
                                                    <Form.Control id="socialInsuranceNumber" type="text" placeholder="" name="socialInsuranceNumber" value={values.socialInsuranceNumber} onChange={handleChange} isValid={touched.socialInsuranceNumber && !errors.socialInsuranceNumber} isInvalid={!!errors.socialInsuranceNumber} />
                                                </Col>
                                            </Row>
                                        </fieldset>
                                        <fieldset className="mt-3">
                                            <legend>Vehicle Details:</legend>
                                            <Row>
                                                <Col md={3}>
                                                    <Form.Label htmlFor="vehicleType" className="mb-0">Vehicle Type</Form.Label>
                                                    <Select
                                                        className="basic-single"
                                                        id='vehicleType'
                                                        classNamePrefix="select"
                                                        defaultValue={[]}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        name="vehicleType"
                                                        options={vehicleTypes}
                                                        isDisabled={false}
                                                        value={values.vehicleType}
                                                        onChange={(selectedOption: any) => setFieldValue("vehicleType", selectedOption)}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: !!errors.vehicleType ? '#dc3545' : '#ced4da',
                                                            }),
                                                        }}
                                                    />
                                                    {/* <Form.Control.Feedback type="invalid" className={!!errors.residentialStatus?.value ? "d-block" : ""}>
                                                        {errors.residentialStatus?.value}
                                                    </Form.Control.Feedback> */}
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Label htmlFor="vehicleIdentificationNumber" className="mb-0">
                                                        Model No./Vehicle Identification Number
                                                    </Form.Label>
                                                    <Form.Control id="vehicleIdentificationNumber" type="text" placeholder="" name="vehicleIdentificationNumber" value={values.vehicleIdentificationNumber} onChange={handleChange} isValid={touched.vehicleIdentificationNumber && !errors.vehicleIdentificationNumber} isInvalid={!!errors.vehicleIdentificationNumber} />
                                                    <Form.Control.Feedback type="invalid" className={!!errors.vehicleIdentificationNumber ? "d-block" : ""}>
                                                        {errors.vehicleIdentificationNumber}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Label htmlFor="firstName" className="mb-0">
                                                        Vehicle Registration Plate
                                                    </Form.Label>
                                                    <Form.Control id="vehicleRegistrationPlate" type="text" placeholder="" name="vehicleRegistrationPlate" value={values.vehicleRegistrationPlate} onChange={handleChange} isValid={touched.vehicleRegistrationPlate && !errors.vehicleRegistrationPlate} isInvalid={!!errors.vehicleRegistrationPlate} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.vehicleRegistrationPlate}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col md={4} className="mt-3">
                                                    <Form.Label htmlFor="firstName" className="mb-0">
                                                        Driving License Image
                                                    </Form.Label>
                                                    <div>
                                                        <img src={initialValues?.drivingLicenseImage} height={'200px'} /><br />
                                                    </div>
                                                    <div className="pt-3">
                                                        <a className="btn btn-sm btn-secondary" href={initialValues?.drivingLicenseImage} target={"_blank"} download>
                                                            View
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col md={4} className="mt-3">
                                                    <Form.Label htmlFor="firstName" className="mb-0">
                                                        Vehicle Insurance Image
                                                    </Form.Label>
                                                    <div>
                                                        <img src={initialValues?.vehicleRegistrationCertificateImage} height={'200px'} /><br />
                                                    </div>
                                                    <div className="pt-3">
                                                        <a className="btn btn-sm btn-secondary" href={initialValues?.vehicleRegistrationCertificateImage} target={"_blank"} download>
                                                            View
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col md={4} className="mt-3">
                                                    <Form.Label htmlFor="firstName" className="mb-0">
                                                        Vehicle Registration Certificate Image
                                                    </Form.Label>
                                                    <div>
                                                        <img src={initialValues?.vehicleInsuranceImage} height={'200px'} /><br />
                                                    </div>
                                                    <div className="pt-3">
                                                        <a className="btn btn-sm btn-secondary" href={initialValues?.vehicleInsuranceImage} target={"_blank"} download>
                                                            View
                                                        </a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </fieldset>
                                        <div className="mt-3 mb-5">
                                            <button className="btn btn-info" type="submit">{editMode ? "Update" : "Submit"} </button>
                                        </div>
                                    </Form>
                                )
                                }
                            </Formik >
                        </Col>
                    </Row>
                </div>
            </main >
        </MainLayout >
    )
}

export default Driver;