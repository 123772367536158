import { Props } from "../../types";
import Table from "../../components/ui-components/table/Table";
import MainLayout from "../../components/layouts/MainLayout";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import ThemeTable, { Column, NoDataFound } from "../../components/ui-components/table/ThemeTable";
import defaultPNG from "../../assets/img/avatar/avatar-illustrated-02.png";
import defaultWEBP from "../../assets/img/avatar/avatar-illustrated-02.webp";
import { Button, Dropdown } from "react-bootstrap";
import Paginator from "../../components/ui-components/pagination/Paginator";
import useTitle from "../../hooks/useTitle";
import Swal from "sweetalert2";
import { DELETE_ALERT_PROMPT_CONFIG } from "../../config/constants";
import { useNavigate } from "react-router-dom";
import { destroyUserFeedback, getUserFeedback } from "../../store/feedback/userFeedbackSlice";
import { formateDate } from "../../helper/basic";
function UserFeedback(props: Props) {
    useTitle('Customer Feedback');

    const columns: Array<Column> = [
        { label: "Profile", accessor: "profile" },
        { label: "User Details", accessor: "user_details" },
        { label: "Feedback/Message", accessor: "feedback" },
        { label: "Date", accessor: "date" },
        { label: "Actions", accessor: "actions" },
    ];

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.auth.accessToken);
    const userFeedback = useAppSelector((state) => state.userFeedback.all);
    const totalPages = useAppSelector((state) => state.userFeedback.totalPages);
    const [pageNumber, setPageNumber] = useState(1);
    const [query, setQuery] = useState("");
    useEffect(() => {
        const params = { query: query, pageNumber: pageNumber };
        if (!token) return;
        dispatch(getUserFeedback({ params, token }));
    }, [pageNumber, query]);

    const onPageChange = (event: any, number: number) => {
        setPageNumber(number);
    }
    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        setPageNumber(1);
    }

    const onDelete = (id: string) => {
        Swal.fire(DELETE_ALERT_PROMPT_CONFIG).then((actions) => {
            if (actions.isConfirmed) {
                if (!token) return;
                dispatch(destroyUserFeedback({ id, token }));
            }
        });
    }
    return (
        <MainLayout isSearchable={true} query={query} queryPlaceholder="Enter Name or Number ..." onSearchInputChange={onSearchInputChange}>
            <main className="main users chart-page" id="skip-target">
                <div className="container-fluid">
                    <h2 className="main-title">Customer Feedback</h2>
                    <div className="row">
                        <div className="col-lg-12">
                            <ThemeTable columns={columns} >
                                <tbody>
                                    {
                                        userFeedback && userFeedback.map((feedback) => {
                                            return (
                                                <tr key={feedback?._id}>
                                                    <td width={"8%"}>
                                                        <div className="pages-table-img">
                                                            <picture>
                                                                <source srcSet={feedback?.usersRef?.profilePic ? feedback?.usersRef?.profilePic : defaultWEBP} type="image/webp" className="avatar-sm" />
                                                                <img src={feedback?.usersRef?.profilePic ? feedback?.usersRef?.profilePic : defaultPNG} alt="User avatar" className="avatar-sm" />
                                                            </picture>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5>{feedback?.name} </h5>
                                                        {feedback?.email ? <><b className="me-3">Email:</b> {feedback?.email}<br /> </> : null}
                                                        <span className="me-3 fw-normal">Phone:</span >{feedback?.usersRef?.dialCode}-{feedback?.usersRef?.phoneNumber}<br />
                                                    </td>
                                                    <td width={"50%"}>
                                                        {feedback?.message}
                                                    </td>
                                                    <td>
                                                        {formateDate(feedback?.createdAt)}
                                                    </td>
                                                    <td>
                                                        <div className="d-flex gap-2">
                                                            <Button size="sm" variant="danger" className="rounded-pill ps-3 pe-3" onClick={(e) => onDelete(feedback?._id)} >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {(userFeedback && userFeedback.length !== 0) ? null : <NoDataFound colSpan={5} />}
                                </tbody>
                            </ThemeTable>
                            <Paginator totalPages={totalPages} pageNo={pageNumber} onPageChange={onPageChange} />
                        </div>
                    </div>
                </div>
            </main>
        </MainLayout >
    )
}

export default UserFeedback;