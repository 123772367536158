import MainLayout from "../../components/layouts/MainLayout";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import ThemeTable, { Column, NoDataFound } from "../../components/ui-components/table/ThemeTable";
import Paginator from "../../components/ui-components/pagination/Paginator";
import useTitle from "../../hooks/useTitle";
import { Link, useNavigate } from "react-router-dom";
import { DELETE_ALERT_PROMPT_CONFIG } from "../../config/constants";
import { getOrders } from "../../store/order/orderSlice";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import LeafletMap from "../../components/map";
import { Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { getZones } from "../../store/zone/zoneSlice";
import { getClusters } from "../../store/cluster/clusterSlice";
import { getTimeSlots } from "../../store/timeslot/timeSlotSlice";
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { formateDate } from "../../helper/basic";
function Orders() {
    useTitle('Orders');
    const columns: Array<Column> = [
        { label: "OrderID", accessor: "order_id" },
        { label: "Customer", accessor: "customer" },
        { label: "Restaurant", accessor: "restaurant" },
        { label: "Driver Details", accessor: "driver_details" },
        { label: "Status", accessor: "status" },
        { label: "Orders Details", accessor: "orders_details" },
        { label: "Payment Summary", accessor: "payment_summary" },
    ];
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.auth.accessToken);
    const orders = useAppSelector((state) => state.orders.all);
    const { totalPages } = useAppSelector((state) => state.orders);
    const [pageNumber, setPageNumber] = useState(1);
    const [query, setQuery] = useState("");
    const zones = useAppSelector((state) => state.zones.all);
    const clusters = useAppSelector((state) => state.clusters.all);
    const slots = useAppSelector((state) => state.timeSlots.all);
    const initialHeaders: {
        query: string;
        pageNumber: number;
        zoneID: undefined | string;
        clusterID: undefined | string;
        slotID: undefined | string;
        startDate: undefined | Date;
        endDate: undefined | Date;
    } = {
        query: query,
        pageNumber: pageNumber,
        zoneID: undefined,
        clusterID: undefined,
        slotID: undefined,
        startDate: undefined,
        endDate: undefined,
    }
    const [apiHeaders, setApiHeaders] = useState(initialHeaders);
    useEffect(() => {
        if (!token) return;
        const params = { query: '', pageNumber: 1, documentLimit: 200 }
        dispatch(getZones({ params, token }));
        dispatch(getClusters({ params, token }));
        dispatch(getTimeSlots({ params, token }));
    }, [])
    useEffect(() => {
        if (!token) return;
        const finalData = {
            ...apiHeaders,
            startDate: apiHeaders.startDate ? moment(apiHeaders.startDate).utcOffset(0, true).format('YYYY-MM-DD') : '',
            endDate: apiHeaders.endDate ? moment(apiHeaders.endDate).utcOffset(0, true).format('YYYY-MM-DD') : '',
        }
        dispatch(getOrders({ params: finalData, token }));
    }, [apiHeaders]);

    const onPageChange = (event: any, number: number) => {
        setPageNumber(number);
        setApiHeaders({ ...apiHeaders, pageNumber: number });
    }
    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        setPageNumber(1);
        setApiHeaders({ ...apiHeaders, query: e.target.value });
    }
    const [show, setShow] = useState(false);
    const [singleOrder, setSingleOrder] = useState<any>();
    const handleClose = () => setShow(false);
    const handleShow = (id: string) => {
        setShow(true);
        const singleOrder = orders.find((order: any) => order._id === id);
        setSingleOrder(singleOrder);
    }
    useEffect(() => {
    }, [singleOrder])

    const onFilterReset = () => {
        setApiHeaders(initialHeaders);
        setQuery("");
        setPageNumber(1);
    }
    return (
        <MainLayout isSearchable={true} query={query} queryPlaceholder="Enter Order ID ..." onSearchInputChange={onSearchInputChange}>
            <main className="main users chart-page" id="skip-target">
                <div className="container-fluid">
                    <h2 className="main-title mb-0">Orders</h2>
                    <div className="flex ">
                        <Row>
                            <Col md={2}>
                                <Form.Label htmlFor='zones' className="mb-0 lh-1">Zones</Form.Label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    id='zones'
                                    defaultValue={[]}
                                    isClearable={false}
                                    isSearchable={true}
                                    isMulti={false}
                                    name="zones"
                                    value={zones?.filter((option: any) => option.value === apiHeaders.zoneID)[0]}
                                    options={zones?.map((zone: any) => ({ ...zone, value: zone._id, label: `${zone.name} (${zone.zoneID})` }))}
                                    onChange={(selectedOption: any) => setApiHeaders({ ...apiHeaders, zoneID: selectedOption.value })}
                                />

                            </Col>
                            <Col md={2}>
                                <Form.Label htmlFor='clusters' className="mb-0 lh-1">Clusters</Form.Label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    id='clusters'
                                    defaultValue={[]}
                                    isClearable={false}
                                    isSearchable={true}
                                    isMulti={false}
                                    name="clusters"
                                    value={clusters?.filter((option: any) => option.value === apiHeaders.clusterID)[0]}
                                    options={clusters?.map((cluster: any) => ({ ...cluster, value: cluster._id, label: `${cluster.name} (${cluster.clusterID})` }))}
                                    onChange={(selectedOption: any) => setApiHeaders({ ...apiHeaders, clusterID: selectedOption.value })}
                                />
                            </Col>
                            <Col md={2}>
                                <Form.Label htmlFor='slots' className="mb-0 lh-1">Slots</Form.Label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    id='slots'
                                    defaultValue={[]}
                                    isClearable={false}
                                    isSearchable={true}
                                    isMulti={false}
                                    name="slots"
                                    value={slots?.filter((option: any) => option.value === apiHeaders.slotID)[0]}
                                    options={slots?.map((slot: any) => ({ ...slot, value: slot._id, label: `${slot.name} (${slot.startTime} - ${slot.endTime} )` }))}
                                    onChange={(selectedOption: any) => setApiHeaders({ ...apiHeaders, slotID: selectedOption.value })}
                                />
                            </Col>
                            <Col md={3}>
                                <Form.Label className="mb-0 lh-1">Date</Form.Label>
                                <div className="d-flex gap-2">
                                    <ReactDatePicker
                                        timeFormat="YYYY-MM-DD"
                                        selected={apiHeaders.startDate}
                                        onChange={(date: Date,) => setApiHeaders({ ...apiHeaders, startDate: date })}
                                        selectsStart
                                        startDate={apiHeaders.startDate}
                                        endDate={apiHeaders.endDate}
                                        placeholderText='Start Date'
                                        className='form-control' />
                                    <ReactDatePicker
                                        timeFormat="YYYY-MM-DD"
                                        selected={apiHeaders.endDate}
                                        onChange={(date: Date,) => setApiHeaders({ ...apiHeaders, endDate: date })}
                                        selectsEnd
                                        startDate={apiHeaders.startDate}
                                        endDate={apiHeaders.endDate}
                                        minDate={apiHeaders.startDate}
                                        placeholderText='End Date'
                                        className='form-control' />
                                </div>
                            </Col>

                            <Col md={2} className="d-flex justify-content-start align-items-end">
                                <div>
                                    <Button size="sm" variant="primary" className="ps-3 pe-3 py-2" onClick={onFilterReset} >
                                        Reset
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Modal show={show} onHide={handleClose} size="lg" centered>
                                <Modal.Header closeButton className="border-0">
                                    <Modal.Title>Order Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="card">
                                        <div className="card-header px-4 py-2">
                                            <h6 className="text-muted mb-0"><span>#{singleOrder?._id}</span></h6>
                                        </div>
                                        <div className="card-body p-4">
                                            <div className="d-flex justify-content-between pt-2">
                                                <div className="d-flex flex-column">
                                                    <p className="fw-bold mb-0">Customer Details:</p>
                                                    {
                                                        singleOrder?.usersRef ?
                                                            <>
                                                                <h6 >
                                                                    {singleOrder?.usersRef?.fullName ? <>
                                                                        <Link to={`/users/${singleOrder?.usersRef?._id}`}><b>{singleOrder?.usersRef?.fullName}</b></Link>

                                                                    </> : "N/A"}
                                                                </h6>
                                                                <small>
                                                                    <b>Email: </b> {singleOrder?.usersRef?.email} <br />
                                                                    <b>Phone Number: </b> {singleOrder?.usersRef?.dialCode} {singleOrder?.usersRef?.phoneNumber}<br />
                                                                    {singleOrder?.deliveryAddress?.street}<br />
                                                                    {singleOrder?.deliveryAddress?.city},&nbsp;
                                                                    {singleOrder?.deliveryAddress?.state},&nbsp;
                                                                    {singleOrder?.deliveryAddress?.country} -&nbsp;
                                                                    {singleOrder?.deliveryAddress?.zipCode}<br />
                                                                    {singleOrder?.deliveryAddress?.dialCode} {singleOrder?.deliveryAddress?.phoneNumber}<br />
                                                                </small>

                                                            </> : null

                                                    }
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <p className="fw-bold mb-0"> Restaurant Details: </p>
                                                    {
                                                        singleOrder?.restaurantsRef ?
                                                            <>
                                                                <h6>
                                                                    {singleOrder?.restaurantsRef ? <>
                                                                        <Link to={`/restaurants/edit/${singleOrder?.restaurantsRef?._id}`}><b>{singleOrder?.restaurantsRef.name}</b></Link>
                                                                    </> : "N/A"}
                                                                </h6>
                                                                <small>
                                                                    <b>Email: </b>{singleOrder?.restaurantsRef?.email}<br />
                                                                    <b>Phone Number: </b>{singleOrder?.restaurantsRef?.restaurantPersonDetails?.dialCode}-{singleOrder?.restaurantsRef?.restaurantPersonDetails?.phoneNumber}<br />
                                                                    {singleOrder?.restaurantsRef?.address?.street}<br />
                                                                    {singleOrder?.restaurantsRef?.address?.city},&nbsp;
                                                                    {singleOrder?.restaurantsRef?.address?.state},&nbsp;
                                                                    {singleOrder?.restaurantsRef?.address?.country} -&nbsp;
                                                                    {singleOrder?.restaurantsRef?.address?.zipCode}
                                                                </small>
                                                            </> : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-4 mt-2">
                                                <div className="flex flex-column">
                                                    <p className="small mb-0"><b>Order No:</b> {singleOrder?.orderID ? singleOrder?.orderID : "N/A"}</p>
                                                    <p className="small mb-0"><b>Batch ID:</b> {singleOrder?.batchID !== "" ? singleOrder?.batchID : null}</p>
                                                </div>
                                                <p className="small mb-0" >
                                                    <b>Date & Time</b>: {formateDate(singleOrder?.createdAt)}
                                                </p>
                                            </div>

                                            {singleOrder?.foodItems && singleOrder?.foodItems.map((foodItem: any, index: number) => {
                                                return (
                                                    <div className="card shadow-0 border-0 mb-1 ">
                                                        <div className="card-body p-1">
                                                            <div className="row">
                                                                <div className="col-md-2">
                                                                    <img src={foodItem?.image} className="img-fluid" style={{ height: "50px" }} alt={foodItem?.name} />
                                                                </div>
                                                                <div className="col-md-3 text-center d-flex justify-content-center align-items-center">
                                                                    <p className="text-muted mb-0">{foodItem?.name}</p>
                                                                </div>
                                                                {/* <div className="col-md-2 text-center d-flex justify-content-center align-items-center">
                                                                    <p className="text-muted mb-0 small">White</p>
                                                                </div> */}
                                                                <div className="col-md-2 text-center d-flex justify-content-center align-items-center">
                                                                    <p className="text-muted mb-0 small">Qty: {foodItem?.quantity}</p>
                                                                </div>
                                                                <div className="col-md-2 text-center d-flex justify-content-center align-items-center">
                                                                    <p className="text-muted mb-0 small">C${foodItem?.price}</p>
                                                                </div>
                                                                <div className="col-md-3 text-center d-flex justify-content-center align-items-center">
                                                                    {
                                                                        ['draft', 'placed', 'accepted', 'preparing', 'ready'].includes(singleOrder?.orderStatus) ? <span className="app-badge w-100 px-3 badge-active">{singleOrder?.orderStatus}</span> : null
                                                                    }
                                                                    {
                                                                        ['pending-payment',].includes(singleOrder?.orderStatus) ? <span className="app-badge w-100 px-3 badge-active">{singleOrder?.orderStatus}</span> : null
                                                                    }
                                                                    {
                                                                        ['assigned', 'out-for-delivery', 'in-transit', 'delayed'].includes(singleOrder?.orderStatus) ? <span className="app-badge w-100 px-3 badge-pending">{singleOrder?.orderStatus}</span> : null
                                                                    }
                                                                    {
                                                                        ['delivered', 'completed'].includes(singleOrder?.orderStatus) ? <span className="app-badge w-100 px-3 badge-success">{singleOrder?.orderStatus}</span> : null
                                                                    }
                                                                    {
                                                                        ['payment-failed', 'payment-cancel', 'cancelled'].includes(singleOrder?.orderStatus) ? <span className="app-badge w-100 px-3 badge-trashed">{singleOrder?.orderStatus}</span> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                            <hr className="mb-2" />
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <div className="d-flex flex-column">
                                                <div className="flex mb-4">
                                                    <p className="fw-bold text-muted mb-0">Delivery Instruction</p>
                                                    <p className="text-muted mb-0">
                                                        {singleOrder?.deliveryInstruction?.instruction}
                                                    </p>
                                                </div>
                                            </div>

                                            {
                                                singleOrder?.proofOfDelivery ?
                                                    <div className="d-flex flex-column mb-3">
                                                        <LeafletMap
                                                            center={[singleOrder?.proofOfDelivery?.lat ?? 26.3695517, singleOrder?.proofOfDelivery?.lng ?? 83.7904293]} zoom={13}
                                                            markers={[{
                                                                lat: parseFloat(singleOrder?.proofOfDelivery?.lat) ?? 26.3695517,
                                                                lng: parseFloat(singleOrder?.proofOfDelivery?.lng) ?? 83.7904293
                                                            }]} />
                                                    </div>
                                                    : null}
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <div className="flex mb-2">
                                                        <p className="fw-bold mb-0">Delivery Details</p>
                                                        <p className="text-muted mb-0">
                                                            {
                                                                singleOrder?.driversRef ?
                                                                    <div>
                                                                        <Link to={`/drivers/${singleOrder?.driversRef?._id}`}><b>{singleOrder?.driversRef?.fullName}</b></Link>
                                                                        <br />
                                                                        {singleOrder?.driversRef?.dialCode}-{singleOrder?.driversRef?.phoneNumber}
                                                                    </div>
                                                                    : 'N/A'
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="flex ">
                                                        <p className="fw-bold mb-0 text-muted mb-3">Proof of delivery</p>
                                                        {
                                                            singleOrder?.proofOfDelivery ?
                                                                <>
                                                                    <div className="d-flex justify-between flex-column gap-3">
                                                                        <div>
                                                                            <img src={singleOrder?.proofOfDelivery?.podImage} className="img-fluid" style={{ height: "200px" }} alt="Proof of delivery" />
                                                                            <div className="d-flex gap-2 mt-2">
                                                                                <Link to={singleOrder?.proofOfDelivery?.podImage} target="_blank" className="btn btn-sm btn-outline-primary">View POD</Link>
                                                                                <Link to={`https://www.google.com/maps/@${singleOrder?.proofOfDelivery?.lat ?? 26.3695517},${singleOrder?.proofOfDelivery?.lng ?? 83.7904293},12.55z?entry=ttu`} target="_blank" className="btn btn-sm btn-outline-primary">View Location</Link>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <div>
                                                    <table className="border-0 ">
                                                        <tbody>
                                                            <tr className="text-muted">
                                                                <td><span className="fw-bold me-4 ">Subtotal</span></td>
                                                                <td> C$ {singleOrder?.subTotal}</td>
                                                            </tr>
                                                            <tr className="text-muted">
                                                                <td><span className="fw-bold me-4 ">Tax</span></td>
                                                                <td>C$ {singleOrder?.tax}</td>
                                                            </tr>
                                                            <tr className="text-muted">
                                                                <td><span className="fw-bold me-4 ">Tip</span></td>
                                                                <td>C$ {singleOrder?.tip}</td>
                                                            </tr>
                                                            <tr className="text-muted">
                                                                <td><span className="fw-bold me-4 ">Service Fees</span></td>
                                                                <td>C$ {singleOrder?.serviceFees}</td>
                                                            </tr>
                                                            <tr className="text-muted">
                                                                <td><span className="fw-bold me-4 ">Delivery Charges</span></td>
                                                                <td>C$ {singleOrder?.deliveryCharges}</td>
                                                            </tr>
                                                            <tr className="text-muted mt-2">
                                                                <td><span className="fw-bold me-4 ">Discounted Amount</span></td>
                                                                <td>C$ -{singleOrder?.discountedAmount}</td>
                                                            </tr>

                                                            <tr className="text-muted">
                                                                <td><span className="fw-bold me-4 ">Total</span></td>
                                                                <td>C$ {singleOrder?.grandTotal}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer border-0 px-3 py-4"  >
                                            {singleOrder?.paymentDetail ?
                                                <>
                                                    <p className="d-flex align-items-center justify-content-end  mb-0">
                                                        <small>
                                                            Payment Method:
                                                            <span className="h6 mb-0 ms-2">{singleOrder?.paymentDetail?.paymentMethod} </span>
                                                        </small>
                                                    </p>
                                                    <h5 className="d-flex align-items-center justify-content-end  text-uppercase mb-0">
                                                        Total Paid:
                                                        <span className="h2 mb-0 ms-2">C$ {parseFloat(singleOrder?.grandTotal)?.toFixed(2)} </span>
                                                    </h5>
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className="border-0">
                                    <Button variant="primary" onClick={handleClose}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <ThemeTable columns={columns} >
                                <tbody>
                                    {
                                        orders && orders.map((order: any) => {
                                            return (
                                                <tr key={order?._id}>
                                                    <td width={"8%"} className="pe-2">
                                                        <h6 className="me-3" title={order?._id}>
                                                            {order?.orderID ? order?.orderID : "N/A"}
                                                        </h6>
                                                        {order?.batchID !== "" ? <p>Batch:: {order?.batchID} </p> : null}
                                                        {order?.batchID !== "" ? <p>Batch Status::
                                                            {
                                                                ['pending',].includes(order?.batchStatus) ? <span className="app-badge badge-active">{order?.batchStatus}</span> : null
                                                            }
                                                            {
                                                                ['processing'].includes(order?.batchStatus) ? <span className="app-badge badge-pending">{order?.batchStatus}</span> : null
                                                            }
                                                            {
                                                                ['completed'].includes(order?.batchStatus) ? <span className="app-badge badge-success">{order?.batchStatus}</span> : null
                                                            }
                                                            {
                                                                ['cancelled', 'in-complete'].includes(order?.batchStatus) ? <span className="app-badge badge-trashed">{order?.batchStatus}</span> : null
                                                            }
                                                        </p> : null}
                                                    </td>
                                                    <td width={"10%"}>
                                                        {
                                                            order?.usersRef ?
                                                                <p>
                                                                    <h6 className="me-3">
                                                                        {order?.usersRef?.fullName ? <>
                                                                            <Link to={`/users/${order?.usersRef?._id}`}><b>{order?.usersRef?.fullName}</b></Link>

                                                                        </> : "N/A"}
                                                                    </h6>
                                                                    {order?.usersRef?.dialCode}
                                                                    {order?.usersRef?.phoneNumber}
                                                                </p> : null
                                                        }
                                                    </td>
                                                    <td width={"18%"}>
                                                        <h6 className="me-3">
                                                            {order?.restaurantsRef ? <>
                                                                <Link to={`/restaurants/edit/${order?.restaurantsRef?._id}`}><b>{order?.restaurantsRef.name}</b></Link>
                                                            </> : "N/A"}
                                                        </h6>
                                                        <small>
                                                            {
                                                                order?.restaurantsRef ? <>
                                                                    <b>{order?.restaurantsRef?.email}</b><br />
                                                                    <span >{order?.restaurantsRef?.restaurantPersonDetails?.dialCode}-{order?.restaurantsRef?.restaurantPersonDetails?.phoneNumber}</span>
                                                                    <br />
                                                                    <br />
                                                                    {order?.restaurantsRef?.address?.street}<br />
                                                                    {order?.restaurantsRef?.address?.city}&nbsp;,
                                                                    {order?.restaurantsRef?.address?.state}&nbsp;,
                                                                    {order?.restaurantsRef?.address?.country}&nbsp; -
                                                                    {order?.restaurantsRef?.address?.zipCode}
                                                                </> : "N/A"
                                                            }
                                                        </small>
                                                    </td>

                                                    <td>
                                                        {
                                                            order?.driversRef ?
                                                                <div>
                                                                    <Link to={`/drivers/${order?.driversRef?._id}`}><b>{order?.driversRef?.fullName}</b></Link>
                                                                    <br />
                                                                    {order?.driversRef?.dialCode}-{order?.driversRef?.phoneNumber}
                                                                </div>
                                                                : 'N/A'
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            ['draft', 'placed', 'accepted', 'preparing', 'ready'].includes(order?.orderStatus) ? <span className="app-badge badge-active">{order?.orderStatus}</span> : null
                                                        }
                                                        {
                                                            ['pending-payment',].includes(order?.orderStatus) ? <span className="app-badge badge-active">{order?.orderStatus}</span> : null
                                                        }
                                                        {
                                                            ['assigned', 'out-for-delivery', 'in-transit', 'delayed'].includes(order?.orderStatus) ? <span className="app-badge badge-pending">{order?.orderStatus}</span> : null
                                                        }
                                                        {
                                                            ['delivered', 'completed'].includes(order?.orderStatus) ? <span className="app-badge badge-success">{order?.orderStatus}</span> : null
                                                        }
                                                        {
                                                            ['payment-failed', 'payment-cancel', 'cancelled'].includes(order?.orderStatus) ? <span className="app-badge badge-trashed">{order?.orderStatus}</span> : null
                                                        }
                                                    </td>
                                                    <td width={"18%"}>
                                                        <small> <b>Date & Time</b>: {formateDate(order?.createdAt)}</small>
                                                        {order?.foodItems && order?.foodItems.map((foodItem: any, index: number) => {
                                                            return (
                                                                <div key={index} className="d-flex gap-2 mt-3">
                                                                    <img src={foodItem?.image} height={"40px"}></img>
                                                                    <p>
                                                                        <h6>{foodItem?.name}</h6>
                                                                        C$ <b>{foodItem?.price}</b> x{foodItem?.quantity}
                                                                    </p>
                                                                </div>
                                                            )
                                                        })}
                                                    </td>
                                                    <td >
                                                        <Button variant="primary" onClick={() => handleShow(order?._id)}>
                                                            View More Detail
                                                        </Button>
                                                        {/* {order?.address?.street}
                                                        <p className="mb-0"><b className="pe-3">Subtotal:</b>  C$ <b>{order?.subTotal}</b> </p>
                                                        <p className="mb-0"><b className="pe-3">Tax:</b>   C$ <b>{order?.tax}</b></p>
                                                        <p className="mb-0"><b className="pe-3">Tip:</b>   C$ <b>{order?.tip}</b></p>
                                                        <p className="mb-0"><b className="pe-3">Service Fees:</b>   C$ <b>{order?.serviceFees}</b></p>
                                                        <p className="mb-0"><b className="pe-3">Delivery Charges:</b>   C$ <b>{order?.deliveryCharges}</b></p>
                                                        <p className="mb-0"><b className="pe-3">Total:</b> C$ <b>{order?.grandTotal}</b></p> */}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {(orders && orders.length !== 0) ? null : <NoDataFound colSpan={9} />}
                                </tbody>
                            </ThemeTable>
                            <Paginator totalPages={totalPages} pageNo={pageNumber} onPageChange={onPageChange} />
                        </div>
                    </div>
                </div >
            </main >
        </MainLayout >
    )
}

export default Orders;