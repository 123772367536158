
import { Props } from "../../types";
import MainLayout from "../../components/layouts/MainLayout";
import ThemeTable, { Column, NoDataFound } from "../../components/ui-components/table/ThemeTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { getRestaurants } from "../../store/restaurant/restaurantSlice";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useTitle from "../../hooks/useTitle";
import { Button } from "react-bootstrap";
function NewRestaurants(props: Props) {
    useTitle("New Restaurants");
    const columns: Array<Column> = [
        { label: "Restaurant Name", accessor: "name" },
        { label: "Cluster ID", accessor: "cluster_id" },
        { label: "Address", accessor: "address" },
        { label: "Person Details", accessor: "person_details" },
        { label: "Status", accessor: "status" },
        { label: "Actions", accessor: "actions" },
    ];
    const restaurants = useAppSelector((state) => state.restaurants.all);
    const token = useAppSelector((state) => state.auth.accessToken);
    const [pageNumber, setPageNumber] = useState(1);
    const [query, setQuery] = useState("");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        const params = { onlyVerified: false, query: query, pageNumber: pageNumber };
        if (!token) return;
        dispatch(getRestaurants({ params, token }));
    }, [pageNumber, query]);
    const onPageChange = (event: any, number: number) => {
        setPageNumber(number);
    }
    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        setPageNumber(1);
    }
    return (
        <MainLayout isSearchable={true} query={query} queryPlaceholder="Enter Name, Email..." onSearchInputChange={onSearchInputChange}>
            <main className="main users chart-page" id="skip-target">
                <div className="container-fluid">
                    <h2 className="main-title">New Restaurants</h2>
                    <div className="row">
                        <div className="col-lg-12">
                            <ThemeTable columns={columns} >
                                <tbody>
                                    {restaurants && restaurants.map((restaurant: any) => {
                                        return (
                                            <tr key={restaurant?._id}>
                                                <td>
                                                    <h6>{restaurant?.name} </h6>
                                                    <p className="m-0">{restaurant?.email}</p>
                                                    <small style={{ fontSize: '10px' }}>{restaurant?.utcOffset}</small>
                                                </td>
                                                <td>
                                                    <h6>{restaurant?.clusterID}</h6>
                                                </td>
                                                <td>
                                                    {restaurant?.address?.street},<br />
                                                    {restaurant?.address?.city}<br />
                                                    {restaurant?.address?.state}, {restaurant?.address?.country} - <b>{restaurant?.address?.zipCode}</b>
                                                </td>
                                                <td>
                                                    <h6>{restaurant?.restaurantPersonDetails?.firstName} {restaurant?.restaurantPersonDetails?.lastName}</h6>
                                                    <b>Email:</b> {restaurant?.restaurantPersonDetails?.email}<br />
                                                    <b>Phone:</b>{restaurant?.restaurantPersonDetails?.dialCode} {restaurant?.restaurantPersonDetails?.phoneNumber}
                                                </td>
                                                <td>
                                                    {restaurant?.isActivated ? <span className="app-badge badge-active">Activated</span> : <span className="app-badge badge-trashed">Inactivated</span>}
                                                    {restaurant?.isVerified ? <span className="app-badge badge-active">Verified</span> : <span className="app-badge badge-trashed">Unverified</span>}
                                                    {restaurant?.isAvailable ? <span className="app-badge badge-active">Available</span> : <span className="app-badge badge-trashed">Unavailable</span>}
                                                </td>
                                                <td style={{ width: "20%" }}>
                                                    <div className="d-flex gap-2">


                                                        <Button size="sm" variant="primary" className="rounded-pill ps-3 pe-3" onClick={() => navigate(`/restaurants/edit/bulk-upload/${restaurant?._id}`)}>
                                                            Menu Item
                                                        </Button>
                                                        <Link to={`/restaurants/verify/${restaurant?._id}`} className="btn btn-sm rounded-pill btn-info">
                                                            Verify Details
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {(restaurants && restaurants.length !== 0) ? null : <NoDataFound colSpan={6} />}
                                </tbody>
                            </ThemeTable>
                        </div>
                    </div>
                </div>
            </main>
        </MainLayout>
    )
}

export default NewRestaurants;