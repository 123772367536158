import { Props } from "../../types";
import MainLayout from "../../components/layouts/MainLayout";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import ThemeTable, { Column, NoDataFound } from "../../components/ui-components/table/ThemeTable";
import defaultPNG from "../../assets/img/avatar/avatar-illustrated-02.png";
import defaultWEBP from "../../assets/img/avatar/avatar-illustrated-02.webp";
import { Button, Dropdown } from "react-bootstrap";
import Paginator from "../../components/ui-components/pagination/Paginator";
import useTitle from "../../hooks/useTitle";
import { destroyDriver, getDrivers } from "../../store/driver/driverSlice";
import Swal from "sweetalert2";
import { DELETE_ALERT_PROMPT_CONFIG } from "../../config/constants";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import Select from 'react-select';
import { getZones } from "../../store/zone/zoneSlice";
import { getClusters } from "../../store/cluster/clusterSlice";
function Drivers(props: Props) {
    useTitle('Drivers');
    const columns: Array<Column> = [
        { label: "Profile Pic", accessor: "person_details" },
        { label: "Driver Details", accessor: "profile_pic" },
        { label: "Residential Status", accessor: "residential_status" },
        { label: "SIN/SSN", accessor: "social_insurance_number" },
        { label: "Vehicle Details", accessor: "vehicle_details" },

        { label: "Cluster ID", accessor: "cluster" },
        { label: "Zone ID", accessor: "zone" },
        { label: "Status", accessor: "status" },
        { label: "Actions", accessor: "actions" },
    ];

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.auth.accessToken);
    const drivers = useAppSelector((state) => state.drivers.all);
    const { totalPages } = useAppSelector((state) => state.drivers);
    const [pageNumber, setPageNumber] = useState(1);
    const [query, setQuery] = useState("");

    const zones = useAppSelector((state) => state.zones.all);
    const clusters = useAppSelector((state) => state.clusters.all);
    const initialHeaders = {
        query: query,
        pageNumber: pageNumber,
        isOnline: '',
        zoneID: undefined,
        clusterID: undefined
    }
    const [apiHeaders, setApiHeaders] = useState(initialHeaders);

    useEffect(() => {
        if (!token) return;
        const params = { query: '', pageNumber: 1, documentLimit: 200 }
        dispatch(getZones({ params, token }));
        dispatch(getClusters({ params, token }));
    }, [])

    useEffect(() => {
        if (!token) return;
        dispatch(getDrivers({ params: apiHeaders, token }));
    }, [apiHeaders, zones, clusters]);

    const onPageChange = (event: any, number: number) => {
        setPageNumber(number);
        setApiHeaders({ ...apiHeaders, pageNumber: number });
    }
    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        setPageNumber(1);
        setApiHeaders({ ...apiHeaders, query: e.target.value });
    }
    const onDelete = (id: string) => {
        Swal.fire(DELETE_ALERT_PROMPT_CONFIG).then((actions) => {
            if (actions.isConfirmed) {
                if (!token) return;
                dispatch(destroyDriver({ id, token }));
            }
        });
    }
    const onFilterReset = () => {
        setApiHeaders(initialHeaders);
        setQuery("");
        setPageNumber(1);
    }
    const status: any = [
        {
            value: '',
            label: 'All'
        },
        {
            value: 'true',
            label: 'Online'
        },
        {
            value: 'false',
            label: 'Offline'
        },
    ]
    return (
        <MainLayout isSearchable={true} query={query} queryPlaceholder="Enter Name or Number ..." onSearchInputChange={onSearchInputChange}>
            <main className="main users chart-page" id="skip-target">
                <div className="container-fluid">
                    <h2 className="main-title">Drivers</h2>

                    <div className="flex ">
                        <Row>
                            <Col md={2}>
                                <Form.Label htmlFor='zones' className="mb-0 lh-1">Zones</Form.Label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    id='zones'
                                    defaultValue={[]}
                                    isClearable={false}
                                    isSearchable={true}
                                    isMulti={false}
                                    name="zones"
                                    value={zones?.filter((option: any) => option.value === apiHeaders.zoneID)[0]}
                                    options={zones?.map((zone: any) => ({ ...zone, value: zone._id, label: `${zone.name} (${zone.zoneID})` }))}
                                    onChange={(selectedOption: any) => setApiHeaders({ ...apiHeaders, zoneID: selectedOption.value })}
                                />

                            </Col>
                            <Col md={2}>
                                <Form.Label htmlFor='clusters' className="mb-0 lh-1">Clusters</Form.Label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    id='clusters'
                                    defaultValue={[]}
                                    isClearable={false}
                                    isSearchable={true}
                                    isMulti={false}
                                    name="clusters"
                                    value={clusters?.filter((option: any) => option.value === apiHeaders.clusterID)[0]}
                                    options={clusters?.map((cluster: any) => ({ ...cluster, value: cluster._id, label: `${cluster.name} (${cluster.clusterID})` }))}
                                    onChange={(selectedOption: any) => setApiHeaders({ ...apiHeaders, clusterID: selectedOption.value })}
                                />
                            </Col>
                            <Col md={2}>
                                <Form.Label htmlFor='status' className="mb-0 lh-1">Status</Form.Label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    id='status'
                                    defaultValue={[]}
                                    isClearable={false}
                                    isSearchable={true}
                                    isMulti={false}
                                    name="status"
                                    options={status}
                                    value={status?.filter((option: any) => option.value === apiHeaders.isOnline)[0]}
                                    onChange={(selectedOption: any) => setApiHeaders({ ...apiHeaders, isOnline: selectedOption.value })}
                                />
                            </Col>
                            <Col md={2} className="d-flex justify-content-start align-items-end">
                                <div>

                                    <Button size="sm" variant="primary" className="ps-3 pe-3 py-2" onClick={onFilterReset} >
                                        Reset
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>


                    <div className="row">
                        <div className="col-lg-12">
                            <ThemeTable columns={columns} >
                                <tbody>
                                    {
                                        drivers && drivers.map((driver) => {
                                            let profilePic = driver?.profilePic ? driver?.profilePic : undefined;
                                            profilePic = typeof profilePic === 'undefined' || profilePic === null || profilePic === "" ? undefined : profilePic;
                                            return (
                                                <tr key={driver?._id}>
                                                    <td width={"8%"}>
                                                        <div className="pages-table-img">
                                                            <picture>
                                                                <source srcSet={profilePic ? profilePic : defaultWEBP} type="image/webp" className="avatar-sm" />
                                                                <img src={profilePic ? profilePic : defaultPNG} alt="driver avatar" className="avatar-sm" />
                                                            </picture>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5>{driver?.firstName} {driver?.lastName}</h5>
                                                        {driver?.email ? <><b className="me-3">Email :</b> {driver?.email}<br /> </> : null}
                                                        <span className="me-3 fw-normal">Phone :</span >{driver?.dialCode}-{driver?.phoneNumber}<br />
                                                        <span className="me-3 fw-normal">OTP :</span>{driver?.otp}<br />
                                                        <span className="me-3 fw-normal">Gender :</span>{driver?.gender}<br />
                                                        <span className="me-3 fw-normal">Date of Birth :</span>{driver?.birthday}<br />
                                                        <span className="me-3 fw-normal">Rating:</span>{driver?.rating ?? 0}<br />
                                                    </td>
                                                    <td>
                                                        {driver?.residentialStatus}
                                                    </td>
                                                    <td>
                                                        {driver?.socialInsuranceNumber}
                                                    </td>
                                                    <td>{
                                                        driver?.vehicleDetails ?
                                                            <>
                                                                <b className="me-3">Type:</b>  {driver?.vehicleDetails?.vehicleType} <br />
                                                                <span className="me-3 fw-normal" title="The Vehicle Identification Number">Model:</span >{driver?.vehicleDetails?.vehicleIdentificationNumber}<br />
                                                                <span className="me-3 fw-normal" title="Vehicle Registration Plate">VRP:</span>{driver?.vehicleDetails?.vehicleRegistrationPlate}
                                                            </> : null}
                                                    </td>
                                                    <td>
                                                        <b className="me-2">{driver?.clustersRef?.clusterID} </b><br /><>{driver?.clustersRef?.name} </>
                                                    </td>
                                                    <td>
                                                        <b className="me-2">{driver?.zonesRef?.zoneID} </b><br /><>{driver?.zonesRef?.name} </>
                                                    </td>
                                                    <td>
                                                        {driver?.isAvailable ? <span className="app-badge badge-success">Online</span> : <span className="app-badge badge-pending">Offline</span>}
                                                        {driver?.isActivated ? <span className="app-badge badge-active">Activated</span> : <span className="app-badge badge-trashed">Inactivated</span>}
                                                        {driver?.isVerified ? <span className="app-badge badge-active">Verified</span> : <span className="app-badge badge-trashed">Unverified</span>}
                                                    </td>
                                                    <td>
                                                        <div className="d-flex gap-2">
                                                            <Button size="sm" variant="info" className="rounded-pill ps-3 pe-3" onClick={(e) => navigate(`/drivers/${driver?._id}`)}>
                                                                Edit
                                                            </Button>
                                                            <Button size="sm" variant="danger" className="rounded-pill ps-3 pe-3" onClick={(e) => onDelete(driver?._id)} >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {(drivers && drivers.length !== 0) ? null : <NoDataFound colSpan={9} />}
                                </tbody>
                            </ThemeTable>
                            <Paginator totalPages={totalPages} pageNo={pageNumber} onPageChange={onPageChange} />
                        </div>
                    </div>
                </div>
            </main>
        </MainLayout >
    )
}

export default Drivers;