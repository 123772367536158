import { Props } from "../../types";
import MainLayout from "../../components/layouts/MainLayout";
import ThemeTable, { Column, NoDataFound } from "../../components/ui-components/table/ThemeTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { destroyRestaurant, getRestaurants } from "../../store/restaurant/restaurantSlice";
import { useEffect, useState } from "react";
import useTitle from "../../hooks/useTitle";
import Paginator from "../../components/ui-components/pagination/Paginator";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { DELETE_ALERT_PROMPT_CONFIG } from "../../config/constants";
import { useNavigate } from "react-router-dom";
function Restaurant(props: Props) {
    useTitle("Restaurants")
    const columns: Array<Column> = [
        { label: "Restaurant Name", accessor: "name" },
        { label: "Cluster ID", accessor: "cluster_id" },
        { label: "Address", accessor: "address" },
        { label: "Person Details", accessor: "person_details" },
        { label: "Status", accessor: "status" },
        { label: "Actions", accessor: "actions" },
    ];
    const restaurants = useAppSelector((state) => state.restaurants.all);
    const { totalPages } = useAppSelector((state) => state.restaurants);
    const token = useAppSelector((state) => state.auth.accessToken);
    const [pageNumber, setPageNumber] = useState(1);
    const [query, setQuery] = useState("");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        const params = { query: query, pageNumber: pageNumber };
        if (!token) return;
        dispatch(getRestaurants({ params: params, token }));
    }, [pageNumber, query]);
    const onPageChange = (event: any, number: number) => {
        setPageNumber(number);
    }
    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        setPageNumber(1);
    }
    const onDelete = (id: string) => {
        Swal.fire(DELETE_ALERT_PROMPT_CONFIG).then((actions) => {
            if (actions.isConfirmed) {
                if (!token) return;
                dispatch(destroyRestaurant({ id, token }));
            }
        });
    }
    return (
        <MainLayout isSearchable={true} query={query} queryPlaceholder="Enter Name, Email..." onSearchInputChange={onSearchInputChange}>
            <main className="main users chart-page" id="skip-target">
                <div className="container-fluid">
                    <h2 className="main-title">All Restaurants</h2>
                    <div className="row">
                        <div className="col-lg-12">
                            <ThemeTable columns={columns}>
                                <tbody>
                                    {restaurants && restaurants.map((restaurant: any) => {
                                        return (
                                            <tr key={restaurant?._id}>
                                                <td>
                                                    <h6>{restaurant?.name}</h6>
                                                    <small style={{ fontSize: '10px' }}>{restaurant?.email}<br /></small>
                                                    {restaurant?.bankAccountDetails?.holderName && restaurant?.bankAccountDetails?.bankName ? (
                                                    <small style={{ fontSize: '10px' }}>
                                                        {restaurant.bankAccountDetails.holderName} <br /> {restaurant.bankAccountDetails.bankName}
                                                        <br />
                                                    </small>
                                                    ) : (
                                                    <></>
                                                    )}
                                                    {restaurant?.bankAccountDetails?.institutionNumber ? (
                                                    <small style={{ fontSize: '10px' }}>
                                                        {restaurant.bankAccountDetails.institutionNumber?.toString().padStart(3, '0')}-
                                                        {restaurant.bankAccountDetails.transitNumber?.toString().padStart(5, '0')}-
                                                        {restaurant.bankAccountDetails.accountNumber?.toString().padStart(7, '0')}
                                                    </small>
                                                    ) : (
                                                    <></>
                                                    )}
                                                    
                                                    
                                                    
                                                    
                                                    
                                                </td>
                                                <td>
                                                    {restaurant?.clusterID ? restaurant?.clusterID : <span className="text-danger">N/A</span>}
                                                </td>
                                                <td>
                                                    {restaurant?.address?.street},<br />
                                                    {restaurant?.address?.city}<br />
                                                    {restaurant?.address?.state}, {restaurant?.address?.country} - <b>{restaurant?.address?.zipCode}</b>
                                                </td>
                                                <td>
                                                    <h6>{restaurant?.restaurantPersonDetails?.firstName} {restaurant?.restaurantPersonDetails?.lastName}</h6>
                                                    <b>Email:</b> {restaurant?.restaurantPersonDetails?.email}<br />
                                                    <b>Phone:</b>{restaurant?.restaurantPersonDetails?.dialCode} {restaurant?.restaurantPersonDetails?.phoneNumber}
                                                </td>
                                                <td>
                                                    {restaurant?.isActivated ? <span className="app-badge badge-active">Activated</span> : <span className="app-badge badge-trashed">Inactivated</span>}
                                                    {restaurant?.isVerified ? <span className="app-badge badge-active">Verified</span> : <span className="app-badge badge-trashed">Unverified</span>}
                                                    {restaurant?.isAvailable ? <span className="app-badge badge-active">Available</span> : <span className="app-badge badge-trashed">Unavailable</span>}
                                                </td>
                                                <td style={{ width: "20%" }}>
                                                    <div className="d-flex gap-2">
                                                        <Button size="sm" variant="primary" className="rounded-pill ps-3 pe-3" onClick={() => navigate(`/restaurants/edit/bulk-upload/${restaurant?._id}`)}>
                                                            Menu Item
                                                        </Button>
                                                        <Button size="sm" variant="info" className="rounded-pill ps-3 pe-3" onClick={() => navigate(`/restaurants/edit/${restaurant?._id}`)}>
                                                            Edit
                                                        </Button>
                                                        <Button size="sm" variant="danger" className="rounded-pill ps-3 pe-3" onClick={(e) => onDelete(restaurant?._id)} >
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {(restaurants && restaurants.length !== 0) ? null : <NoDataFound colSpan={6} />}
                                </tbody>
                            </ThemeTable>
                            <Paginator totalPages={totalPages} pageNo={pageNumber} onPageChange={onPageChange} />
                        </div>
                    </div>
                </div>
            </main>
        </MainLayout >
    )
}

export default Restaurant;