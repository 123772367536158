import { Props } from "../../types";
import MainLayout from "../../components/layouts/MainLayout";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import ThemeTable, { Column, NoDataFound } from "../../components/ui-components/table/ThemeTable";
import { Button, Dropdown } from "react-bootstrap";
import Paginator from "../../components/ui-components/pagination/Paginator";
import useTitle from "../../hooks/useTitle";
import { Link, useNavigate } from "react-router-dom";
import { destroyZone, getZones } from "../../store/zone/zoneSlice";
import Swal from "sweetalert2";
import { DELETE_ALERT_PROMPT_CONFIG } from "../../config/constants";
function Zones(props: Props) {
    useTitle('Delivery Zones');
    const columns: Array<Column> = [
        { label: "Zone Name", accessor: "zone_name" },
        { label: "Address", accessor: "basic_address" },
        { label: "Delivery Charges", accessor: "deliveryCharges" },
        { label: "Service Fees", accessor: "serviceFees" },
        { label: "Tip", accessor: "tip" },
        { label: "Min. Order Amount With Fees", accessor: "minimumOrderAmountWithFee" },
        { label: "Min. Order Amount", accessor: "minimumOrderAmount" },
        { label: "Actions", accessor: "actions" },
    ];

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const token = useAppSelector((state) => state.auth.accessToken);
    const zones = useAppSelector((state) => state.zones.all);
    const { totalPages } = useAppSelector((state) => state.zones);
    const [pageNumber, setPageNumber] = useState(1);
    const [query, setQuery] = useState("");
    useEffect(() => {
        const params = { query: query, pageNumber: pageNumber };
        if (!token) return;
        dispatch(getZones({ params, token }));
    }, [pageNumber, query]);

    const onPageChange = (event: any, number: number) => {
        setPageNumber(number);
    }
    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        setPageNumber(1);
    }
    const onDelete = (id: string) => {
        Swal.fire(DELETE_ALERT_PROMPT_CONFIG).then((actions) => {
            if (actions.isConfirmed) {
                if (!token) return;
                dispatch(destroyZone({ id, token }));
            }
        });
    }
    return (
        <MainLayout isSearchable={true} query={query} queryPlaceholder="Enter Zone Name or ID ..." onSearchInputChange={onSearchInputChange}>
            <main className="main users chart-page" id="skip-target">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h2 className="main-title mb-0">Delivery Zones</h2>
                        <Link to="/zones/create" className="btn btn-info btn-sm">Add Delivery Zone</Link>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <ThemeTable columns={columns} >
                                <tbody>
                                    {
                                        zones && zones.map((zone: any) => {
                                            return (
                                                <tr key={zone?._id}>
                                                    <td >
                                                       {zone?.name}
                                                    </td>
                                                    <td >
                                                        {zone?.address?.street}
                                                        <p className="mb-0">{zone?.address?.city} </p>
                                                        <p className="mb-0">{zone?.address?.state}</p>
                                                        <p className="mb-0">{zone?.address?.country}  - <b><span className="text-success" title="Postal Code">{zone?.address?.zipCode}</span></b></p>
                                                    </td>
                                                    <td>
                                                        {
                                                            zone?.deliveryCharges?.value ? <><b className="me-2">C$ {zone?.deliveryCharges?.value}</b></> : <b className="me-2">C$ 0</b>
                                                        }
                                                        {
                                                            zone?.deliveryCharges?.type ? <></> : null
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            zone?.serviceFees?.value ? <><b className="me-2">C$ {zone?.serviceFees?.value}</b></> : <b className="me-2">C$ 0</b>
                                                        }
                                                        {
                                                            zone?.serviceFees?.type ? <></> : null
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            zone?.tip?.value ? <><b className="me-2">C$ {zone?.tip?.value}</b></> : <b className="me-2">C$ 0</b>
                                                        }
                                                        {
                                                            zone?.tip?.type ? <></> : null
                                                        }
                                                        
                                                    </td>
                                                    <td>
                                                        {
                                                            zone?.minimumOrderAmountWithFee ? <><b className="me-2">C$ {zone?.minimumOrderAmountWithFee}</b></> : null
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            zone?.minimumOrderAmount ? <><b className="me-2">C$ {zone?.minimumOrderAmount}</b></> : null
                                                        }
                                                    </td>
                                                    
                                                    <td>
                                                        <div className="d-flex gap-2">
                                                            <Button size="sm" variant="info" className="rounded-pill ps-3 pe-3" onClick={() => navigate(`/zones/${zone?._id}`)}>
                                                                Edit
                                                            </Button>
                                                            <Button size="sm" variant="danger" className="rounded-pill ps-3 pe-3" onClick={(e) => onDelete(zone?._id)} >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {(zones && zones.length !== 0) ? null : <NoDataFound colSpan={9} />}
                                </tbody>
                            </ThemeTable>
                            <Paginator totalPages={totalPages} pageNo={pageNumber} onPageChange={onPageChange} />
                        </div>
                    </div>
                </div>
            </main>
        </MainLayout >
    )
}

export default Zones;